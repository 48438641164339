import { Accordion, AccordionSummary, AccordionDetails, Card, CardContent, CardMedia, CircularProgress, Container, Divider, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { loadProductDetail } from '../../api/sockets/store/storeRequests'
import { addToCart } from '../../api/sockets/cart/cartRequests'
import { makeStyles } from '@material-ui/core/styles'
import { productImageBase } from '../../api/base'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import customerPages from '../../enums/customerPages'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import productFlags from '../../enums/productFlags'


const useStyles = makeStyles({
    media: {
        height: 175,
        backgroundSize: 'contain',
    },
})

export const StoreProductDetail = ({ productId, socket, storeId, notify, setSelectedPage, lastCategory, setItemsInCart }) => {
    // TODO: Size Details, add addons/ingredients/size to addToCart, style
    const [fullDetail, setFullDetail] = useState(null)
    const [loading, setLoading] = useState(true)
    const [cartLoading, setCartLoading] = useState(false)
    const [quantity, setQuantity] = useState(1)
    const defaultImageLocation = 'missingimage.jpg'
    const [piChecks, setPIChecks] = useState([])
    const [addOnChecks, setAddOnChecks] = useState([])
    const [total, setTotal] = useState(0);
    const isSmall = useMediaQuery('(max-width:525px)')
    const isBig = useMediaQuery('(min-width:1200px)');
    const [selectedSize, setSelectedSize] = useState(null)

    const [expanded, setExpanded] = useState(false)


    let modifiedLocation = fullDetail?.ImageLocation
    if (modifiedLocation) {
        modifiedLocation = `${productImageBase}${modifiedLocation}`
    }
    const classes = useStyles()

    useEffect(() => {
        if (socket !== null && socket.socket.readyState === WebSocket.OPEN && productId && !fullDetail) {
            reloadProductDetail()
        }

    }, [socket, fullDetail])

    const reloadProductDetail = () => {
        setLoading(true)

        const requestData = {
            StoreAccountId: storeId,
            ProductId: productId
        }

        socket.props.onDetailSuccess = (data) => {
            setFullDetail(data)
            initPIChecks(data)
            initAddOnChecks(data)
            let targetSize = data.PriceDetails.filter(x => x.IsDefaultSize)[0]
            if (!targetSize){
                const lowestPrice = Math.min.apply(null, data.PriceDetails.map(x => x.Price))
                targetSize = data.PriceDetails.filter(x => x.Price === lowestPrice)[0]
            }
            
            setSelectedSize(targetSize.ProductId)

            initProductTotal(data, targetSize)
        }
        socket.props.onDetailFailure = (msg) => {
            notify('Error', msg)
        }
        socket.props.setDetailLoading = setLoading

        loadProductDetail(requestData, socket.socket)
    }

    const initAddOnChecks = (data) => {
        let addOns = []
        data.ProductAddons.map(pa => {
            let paCheck = {
                IngredientId: pa.IngredientId,
                Price: pa.Price,
                Name: pa.Name,
                IsChecked: pa.DefaultOn,
            }
            addOns.push(paCheck)
        })
        setAddOnChecks(addOns)
    }

    const initProductTotal = (data, lowestPrice) => {
        let total = lowestPrice.Price
        data.ProductAddons.forEach(pa => {
            if (pa.DefaultOn) {
                total += pa.Price
            }
        })
        setTotal(Number(total.toFixed(2)))
    }

    const initPIChecks = (data) => {
        let productIngredients = []
        data.ProductIngredients.map(pi => {
            let piCheck = {
                IngredientId: pi.IngredientId,
                IsChecked: true,
                Enabled: pi.Enabled,
            }
            productIngredients.push(piCheck)
        });
        setPIChecks(productIngredients)
    }

    const updatePrice = (ovrQuantity, priceId) => {
        if (!ovrQuantity) {
            ovrQuantity = quantity
        }
        if (!priceId) {
            priceId = selectedSize
        }
        priceId = Number(priceId)
        let price = fullDetail.PriceDetails.filter(x => x.ProductId === priceId)[0].Price
        addOnChecks.forEach(pa => {
            if (pa.IsChecked) {
                price += pa.Price
            }
        })
        ovrQuantity = Number(ovrQuantity)
        if (!isNaN(ovrQuantity) && ovrQuantity > 0) {
            setTotal(price * ovrQuantity)
        }
        else {
            setTotal(price)
        }
    }


    const handleAddOnCheck = (id) => {
        let addOnPrice = 0
        const clone = addOnChecks.map(x => {
            if (x.IngredientId === id) {
                x.IsChecked = !x.IsChecked
                addOnPrice = x.IsChecked ? x.Price : -x.Price
            }
            return x
        })
        setAddOnChecks(clone)
        if (!isNaN(addOnPrice)) {
            setTotal(total + (addOnPrice * quantity))
        }
    }

    const handleIngredientCheck = (id) => {
        const clone = piChecks.map(x => {
            if (x.IngredientId === id) {
                x.IsChecked = !x.IsChecked
            }
            return x
        })
        setPIChecks(clone)
    }

    const addToCartSubmit = (e) => {
        e.preventDefault()
        setCartLoading(true)
        const blackListIngredients = piChecks.filter(p => !p.IsChecked)
        const addons = addOnChecks.filter(a => a.IsChecked)
        const requestData = {
            StoreAccountId: storeId,
            ProductId: Number(selectedSize),
            Quantity: Number(quantity),
            WithoutIngredientIds: blackListIngredients.map(x => x.IngredientId),
            WithAddonIds: addons.map(x => x.IngredientId)
        }

        socket.props.onCartSuccess = (data) => {
            setItemsInCart(data.ItemsInCart)
            localStorage.setItem(`${storeId}_CART`, JSON.stringify(data.ShoppingCart))

            sessionStorage.setItem('CART_SPLASH', JSON.stringify({
                Name: fullDetail.Name,
                ProductAddons: addOnChecks.filter(a => a.IsChecked),
                ProductId: Number(selectedSize),
                Quantity: Number(quantity),
                Total: total,
                WithoutIngredients: piChecks.filter(p => !p.IsChecked), // Fields may change
                PriceDetails: fullDetail.PriceDetails.filter(x => x.ProductId === Number(selectedSize))[0],
                ImageLocation: fullDetail.ImageLocation,
                Description: fullDetail.Description,
            }))

            setSelectedPage(customerPages.addToCartSplash)
        }
        socket.props.onCartFailure = (msg) => {
            notify('Error', msg)
        }
        socket.props.setCartLoading = setCartLoading

        addToCart(requestData, socket.socket)
    }

    return <Fragment>
        {loading || !fullDetail
            ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                <CircularProgress style={{ width: '100px', height: '100px' }} />
            </div>
            : <Container>
                <Row>
                    <Col xl={11} lg={12}>
                        <Card>
                            <Row>
                                <Col lg={4} xs={12}>
                                    {modifiedLocation 
                                        ? <CardMedia
                                            className={classes.media}
                                            image={modifiedLocation || defaultImageLocation}
                                            title={fullDetail.Name}
                                            style={{ opacity: !modifiedLocation ? '0.25' : null, marginTop: '1rem' }}
                                        />
                                        : (
                                            <div 
                                                style={{
                                                    minHeight: '140px', backgroundColor: 'white', 
                                                    padding: '20px', textAlign: 'center',
                                                    borderRadius: '10px', marginTop: '5px'
                                                }}
                                            >
                                                <h2>{fullDetail.Name}</h2>
                                            </div>
                                    )}
                                </Col>
                                <Col lg={2}></Col>
                                {isBig &&
                                    <Divider orientation="vertical" flexItem />}
                                <CardContent>
                                    <Typography variant="h4" style={{ fontSize: isSmall ? '1.5rem' : '1.75rem', paddingTop: '2rem' }}>
                                        {fullDetail.Name}
                                    </Typography>
                                    <Typography variant="body1" style={{ fontSize: '1rem', whiteSpace: 'pre-line' }}>
                                        {fullDetail.Description === '' ? 'No description available' : fullDetail.Description}
                                    </Typography>
                                    <Divider style={{ width: '100%' }}></Divider>
                                </CardContent>
                            </Row>
                            <Form onSubmit={addToCartSubmit}>
                                <Card id="addons" style={{ marginBottom: '30px' }}>
                                    <CardContent>
                                        <Typography style={{ marginLeft: '20px', fontSize: isBig ? '1.5rem' : '1.25rem', paddingBottom: '1rem' }} variant="h5">Choose Your Add Ons</Typography>
                                        {fullDetail?.ProductAddons &&
                                            <Row style={{ marginLeft: isBig ? '5rem' : '1rem' }}>
                                                {fullDetail.ProductAddons.map(a => {
                                                    const addOn = addOnChecks.filter(x => x.IngredientId === a.IngredientId)[0]
                                                    return !addOn ? '' : (
                                                        <Col key={a.IngredientId} lg={ fullDetail.ProductAddons.length > 1 ? '4' : 'auto'} xs={fullDetail.ProductAddons.length > 1 ? '6' : 'auto'}  style={{marginBottom: '1rem'}}>
                                                            <Button
                                                                variant="secondary"
                                                                size="sm"
                                                                style={{  
                                                                    fontSize: isSmall ? '.7rem' : '.85rem',
                                                                    backgroundColor: addOn.IsChecked ? 'green' : '',
                                                                    minHeight: '5rem',
                                                                    minWidth: '6.5rem',
                                                                    maxWidth: '14rem',
                                                                    width: '100%',
                                                                    height: '100%'
                                                                    }}
                                                                active={addOn.IsChecked}
                                                                onClick={() => handleAddOnCheck(a.IngredientId)}>
                                                                <div>
                                                                    {a.Name}
                                                                </div>
                                                                <div>
                                                                    <Form.Label style={{ display: 'inline', paddingLeft: '.8rem', fontSize: isSmall ? '.65rem' : '.8rem', textAlign: 'center' }}>+ ${a.Price.toFixed(2)}</Form.Label>
                                                                </div>
                                                            </Button>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        }
                                    </CardContent>
                                </Card>
                                {
                                fullDetail && !Boolean(fullDetail.Flags & productFlags.NoRemoveItems === productFlags.NoRemoveItems) &&
                                    <Accordion square expanded={expanded} onChange={() => setExpanded(!expanded)} style={{ marginBottom: '30px' }}>
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                                            <Typography style={{ marginLeft: '20px', fontSize: isBig ? '1.5rem' : '1.25rem', paddingBottom: '1rem' }} variant="h5">Remove Ingredients</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {fullDetail?.ProductIngredients &&
                                                <Row style={{ marginLeft: isBig ? '5rem' : '1rem', width: '100%' }}>
                                                    {fullDetail.ProductIngredients.map(i => {
                                                        const ingredient = piChecks.filter(x => x.IngredientId === i.IngredientId)[0]
                                                        return !ingredient ? '' : (
                                                            <Col key={i.IngredientId} lg={fullDetail.ProductIngredients.length > 1 ? '4' : 'auto'} xs={fullDetail.ProductIngredients.length > 1 ? '6' : 'auto'}  style={{marginBottom: '1rem'}}>
                                                                <Button 
                                                                    key={i.IngredientId}
                                                                    variant="secondary"
                                                                    size="sm" 
                                                                    style={{ 
                                                                        fontSize: isSmall ? '.7rem' : '.85rem',
                                                                        backgroundColor: ingredient.IsChecked ? 'green' : '',
                                                                        minHeight: '4rem',
                                                                        minWidth: '6.5rem',
                                                                        maxWidth: '14rem',
                                                                        width: '100%',
                                                                        height: '100%'
                                                                        }} 
                                                                    active={ingredient.IsChecked} 
                                                                    onClick={() => handleIngredientCheck(i.IngredientId)}>
                                                                    <Form.Label>{i.Name}</Form.Label>
                                                                    </Button>
                                                                </Col>
                                                        )
                                                    })}
                                                </Row>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                }
                                <Card>
                                    <Form.Group as={Row}>
                                        <Col></Col>
                                        <Form.Label style={{ textAlign: 'right', fontSize: isSmall ? '.8rem' : '1rem' }} column xs={3} sm={2} lg={2}>
                                            Quantity:
                                        </Form.Label>
                                        <Col lg={3} md={4} sm={4} xs={5}>
                                            <Form.Control value={quantity} min="1" type="number" onChange={(e) => { setQuantity(e.target.value); updatePrice(e.target.value) }} required />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Col></Col>
                                        <Form.Label style={{ textAlign: 'right', paddingRight: '0', fontSize: isSmall ? '.8rem' : '1rem' }} column xs={2} sm={2} lg={2}>
                                            Size:
                                        </Form.Label>
                                        <Col lg={3} md={4} sm={4} xs={7}>
                                            <Form.Control as="select" value={selectedSize} onChange={(e) => { setSelectedSize(e.target.value); updatePrice(null, e.target.value) }}>
                                                {fullDetail && fullDetail.PriceDetails.map(x => {
                                                    return <option key={x.ProductId} value={x.ProductId}>{x.SizeDetails} - ${x.Price.toFixed(2)}</option>
                                                })}
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Col></Col>
                                        <Form.Label style={{ textAlign: 'right', paddingRight: '0', fontSize: isSmall ? '.8rem' : '1rem' }} column xs={2} sm={2} lg={2}>
                                            Total:
                                    </Form.Label>
                                        <Col lg={3} md={4} sm={4} xs={5}>
                                            <Form.Control value={`$${total.toFixed(2)}`} disabled />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Col xl={8} lg={8} md={7} sm={6} xs={6}></Col>
                                        <Col lg={4} md={5} xs={6} className="text-right">
                                            <Button type="submit" disabled={cartLoading} style={{ fontSize: isSmall ? '.8rem' : 'inherit' }}>Add To Cart</Button>
                                        </Col>
                                    </Form.Group>
                                </Card>
                            </Form>
                        </Card>
                    </Col>
                    <Col xl={1} lg={0} sm={0}></Col>
                </Row>
            </Container>
        }
    </Fragment>
}