import React, { useState, Fragment, useRef, useEffect } from 'react'
import { Col, Row, Form, Button } from 'react-bootstrap'
import { CircularProgress, useMediaQuery, makeStyles, TextField, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/AddCircle'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import { getAccountKeys, editAccountKey, createAccountKey, deleteAccountKey } from '../../../api/sockets/account/accountRequests'

const useStyles = makeStyles({
    stickyTH: {
        '& th': {
            position: 'sticky',
            top: '-2px',
            backgroundColor: 'lightgray',
            zIndex: 1
        }
    },
})

export const AccountKeys = ({socket, notify}) => {
    const isXtraSmall = useMediaQuery('(max-width:525px)')
    const isBig = useMediaQuery('(min-width:1200px)')
    const isSmall = useMediaQuery('(max-width:1000px)') // iPad

    const classes = useStyles()
    const [loading, setLoading] = useState(true)

    const [keys, setKeys] = useState(null)

    const [name, setName] = useState('')
    const [kPublic, setKPublic] = useState('')
    const [kPrivate, setKPrivate] = useState('')
    const [payType, setPayType] = useState(1)
    const [code, setCode] = useState('')

    useEffect(() => {
        if (keys === null && socket){
            loadKeys()
        }
    }, [keys])

    const loadKeys = () => {
        setLoading(true)

        socket.props.onListSuccess = (data) => {
            console.log(data)
            setKeys(data.Keys)
        }
        socket.props.onListFailure = (msg) => {
            notify('Loading Error', msg)
        }
        socket.props.setListLoading = setLoading

        getAccountKeys({}, socket.socket)
    }

    const addNewRow = () => {
        const clones = [...keys.map(x => {
            x.IsEdit = false
            return x
        }), {
            AccountKeyId: 0,
            Name: '',
            PaymentPublicKey: '',
            PaymentPrivateKey: '',
            PaymentType: 1,
            TextCode: '',
            CanDelete: true,
            IsNew: true
        }]
        setName('')
        setKPrivate('')
        setKPublic('')
        setPayType(1)
        setCode('')
        setKeys(clones)
    }

    const getPaymentType = (t) => {
        t = Number(t)
        switch (t){
            case 1:
                return 'Card Connect'
            default:                
                return 'Unknown'
        }
    }

    const editRow = (rId) => {
        const clones = keys.filter(x => !x.IsNew).map(x => {
            if (x.AccountKeyId === rId){
                x.IsEdit = true;
                setName(x.Name)
                setKPrivate(x.PaymentPrivateKey)
                setKPublic(x.PaymentPublicKey)
                setPayType(x.PaymentType)
                setCode(x.TextCode)
            }
            else if (x.IsEdit){
                x.IsEdit = false
            }
            return x
        })
        setKeys(clones)
    }

    const cancelRow = (rId) => {
        let clones = keys.map(x => {
            if (x.AccountKeyId === rId){
                x.IsEdit = false
            }
            return x
        })
        if (rId === 0){
            clones = clones.filter(x => !x.IsNew)
        }
        setKeys(clones)
    }

    const saveRow = (rId) => {
        if (loading){
            return
        }
        if (!name){
            notify('Save Error', 'Name Required')
            return
        }
        setLoading(true)
        rId ? saveEditRow(rId) : saveNewRow()
    }

    const saveNewRow = () => {
        socket.props.onListAddSuccess = (data) => {
            loadKeys()
        }
        socket.props.onListAddFailure = (msg) => {
            notify('Save New Error', msg)
        }
        socket.props.setListLoading = setLoading
        const key = {}
        key.PaymentPrivateKey = kPrivate
        key.PaymentPublicKey = kPublic
        key.PaymentType = payType
        key.TextCode = code
        key.Name = name
        createAccountKey(key, socket.socket)
    }
    const saveEditRow = (rId) => {
        const key = keys.filter(x => x.AccountKeyId === rId)[0]
        if (!key){
            setLoading(false)
            return
        }
        socket.props.onListEditSuccess = () => {
            loadKeys()
        }
        socket.props.onListEditFailure = (msg) => {
            notify('Save New Error', msg)
        }
        socket.props.setListLoading = setLoading

        key.PaymentPrivateKey = kPrivate
        key.PaymentPublicKey = kPublic
        key.PaymentType = payType
        key.TextCode = code
        key.Name = name
        editAccountKey(key, socket.socket)
    }

    const deleteRow = (rId) => {
        if (loading || !rId){
            return
        }

        socket.props.onListDeleteSuccess = () => {
            loadKeys()
        }
        socket.props.onListDeleteFailure = (msg) => {
            notify('Delete Error', msg)
        }
        socket.props.setListLoading = setLoading

        const request = {
            AccountKeyId: Number(rId)
        }
        deleteAccountKey(request, socket.socket)
    }

    return <Fragment>
        <Row>
            <Col lg={1}></Col>
            <Col>
                <h4>
                    Account Payment Keys
                </h4>
            </Col>
        </Row>
        <hr />
        {loading
            ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                <CircularProgress style={{ width: '100px', height: '100px' }} />
            </div>
            : <Row>
                <Col xs={12} style={{ height: '65vh', overflowY: 'auto', paddingRight: 0 }}>
                    <table className="table table-striped table-sm table-hover" style={{ maxWidth: isBig ? '60vw' : '100vw', marginLeft: 'auto', marginRight: 'auto' }}>
                        <thead>
                            <tr className={classes.stickyTH}>
                                <th>Name</th>
                                <th>Public</th>
                                <th>Private</th>
                                <th>Payment Type</th>
                                <th>Text Code</th>
                                <th>&nbsp;</th>
                                <th style={{ backgroundColor: 'none', textAlign: 'center' }}>
                                    {!keys.some(x => x.IsNew) && 
                                        <Tooltip title="New Key" placement="right">
                                            <AddIcon fontSize="large" style={{ color: 'green', cursor: 'pointer' }} onClick={() => addNewRow()} />
                                        </Tooltip>
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {keys && keys.map(x => {
                                if (x.IsEdit || x.IsNew){
                                    return <tr key={x.AccountKeyId}>
                                        <td>
                                            <Form.Control value={name} onChange={(e) => setName(e.target.value)} />
                                        </td>
                                        <td>
                                            <Form.Control value={kPublic} disabled={Boolean(x.PaymentPublicKey)} onChange={(e) => setKPublic(e.target.value)} />
                                        </td>
                                        <td>
                                            <Form.Control value={kPrivate} disabled={Boolean(x.PaymentPrivateKey)} onChange={(e) => setKPrivate(e.target.value)} />
                                        </td>
                                        <td>
                                            <Form.Control as="select" value={payType} onChange={(e) => setPayType(e.target.value)} disabled={!x.CanDelete}>
                                                <option value={0}>-- Payment Type --</option>
                                                <option value={1}>CardConnect</option>
                                            </Form.Control>
                                        </td>
                                        <td>
                                            <Form.Control value={code} onChange={(e) => setCode(e.target.value)} />
                                        </td>
                                        <td>
                                            <span>&nbsp;</span>
                                        </td>
                                        <td>
                                            <SaveIcon fontSize="large" onClick={() => saveRow(x.AccountKeyId)} />
                                        </td>
                                    </tr>
                                }
                                return <tr key={x.AccountKeyId}>
                                    <td>
                                        {x.Name}
                                    </td>
                                    <td>
                                        {x.PaymentPublicKey}
                                    </td>
                                    <td>
                                        {x.PaymentPrivateKey}
                                    </td>
                                    <td>
                                        {getPaymentType(x.PaymentType)}
                                    </td>
                                    <td>
                                        {x.TextCode}
                                    </td>
                                    <td>
                                        {x.CanDelete ? <DeleteIcon onClick={() => deleteRow(x.AccountKeyId)} /> : <span>&nbsp;</span>}
                                    </td>
                                    <td>
                                        <EditIcon fontSize="large" onClick={() => editRow(x.AccountKeyId)} />
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </Col>
            </Row>
        }
    </Fragment>
}