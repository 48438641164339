import React, { useState, useEffect } from 'react'

import { Form } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { List, Card, CardHeader, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        padding: theme.spacing(1, 2),
        textAlign: 'center'
    },
    listContainer: {
        minWidth: 250,
        height: 250,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    listItem: {
        paddingTop: 10
    }
}))

export const IngredientList = ({ props }) => {
    const classes = useStyles()
    const { setNotSelectedIngredients, setSelectedIngredients, allIngredients, notSelectedIngredients, selectedIngredients } = props
    const [searchNotSelected, setSearchNotSelected] = useState('')
    const [filteredNotSelectedIngredients, setFilteredNotSelectedIngredients] = useState([])

    useEffect(() => {
        setFilteredNotSelectedIngredients(
            notSelectedIngredients.filter(x => x.Name.toLowerCase().includes(searchNotSelected.toLowerCase()))
        )
        
    }, [searchNotSelected, notSelectedIngredients])

    const addToSelectedIngredients = (ingredientId) => {
        let ingredient = allIngredients.filter(x => x.IngredientId === ingredientId)
        let temp = notSelectedIngredients.filter(x => x.IngredientId !== ingredientId)
        setNotSelectedIngredients(temp)
        setSelectedIngredients(selectedIngredients.concat(ingredient))
    }

    return (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                title='Ingredient List'
            />
            <Form.Control placeholder='Search' onChange={e => setSearchNotSelected(e.target.value)} /> {/* TODO: Make this work */}
            <List className={classes.listContainer} dense component="div" role="list">
                {filteredNotSelectedIngredients.map((x) => {
                    return (
                        <ListItem key={x.IngredientId} className={classes.listItem} role="listitem">
                            <ListItemIcon>
                                <AddCircleIcon color="primary" style={{cursor: 'pointer'}} onClick={() => addToSelectedIngredients(x.IngredientId)} />
                            </ListItemIcon>
                            <ListItemText id={`ingredient-${x.IngredientId}-label`} primary={`${x.Name} (${x.Units})`} />
                        </ListItem>
                    )
                })}
                <ListItem />
            </List>
        </Card>
    )
}
