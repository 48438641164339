import React, { useEffect, useState, Fragment, useRef } from 'react'
import { Row, Col, } from 'react-bootstrap'
import { CircularProgress, makeStyles, useMediaQuery } from '@material-ui/core'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import { Form, Button } from 'react-bootstrap'
import { listProducts } from '../../../api/sockets/product/productRequests'
import activeFilter from '../../../enums/activeFilter'
import { Delete } from '@material-ui/icons'
import { List, Card, CardHeader, ListItem, ListItemIcon, ListItemText, Checkbox } from '@material-ui/core'
import { updateComboItems, updateComboCategory } from '../../../api/sockets/combos/comboRequests'

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        padding: theme.spacing(1, 2),
        textAlign: 'center'
    },
    listContainer: {
        minWidth: 250,
        height: '40vh',
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    listItem: {
        paddingTop: 10
    },
    deleteIcon: {
        cursor: 'pointer',
        '&:hover': {
            color: 'gray'
        },
    }
}))



export const EditComboCategory = ({ socket, notify, comboCategory, back, setComboCategory, productCategories }) => {
    const classes = useStyles()
    const updateForm = useRef(null)
    const [loading, setLoading] = useState(false)
    // Combo Items
    const [updateComboItemsLoading, setUpdateComboItemsLoading] = useState(false)
    const [masterProductList, setMasterProductList] = useState(null) // All Products
    const [filteredAvailableProducts, setFilteredAvailableProducts] = useState(null)
    const [filterAvailableDDL, setFilterAvailbleDDL] = useState(0)
    const [selectAllCheck, setSelectAllCheck] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format


    // Combo Category Update
    const [updateCategoryLoading, setUpdateCategoryLoading] = useState(false)
    const [previousState, setPreviousState] = useState(null)

    useEffect(() => {
        if (socket !== null) {
            if (masterProductList === null) {
                reloadProductList()
            }
            if (previousState === null) {
                setPreviousState({...comboCategory})
            }
        }
    }, [socket])

    const reloadProductList = () => {
        setLoading(true)
        socket.props.setListLoading = setLoading
        socket.props.onListFailure = notify
        socket.props.onListSuccess = (productOverviews) => {
            productOverviews.forEach(product => {
                product.Checked = false
                // Lets delete the info we don't need since we are already here
                delete product.ImageLocation
            });
            setMasterProductList(productOverviews)
            initalizeAvailableProducts(productOverviews)

        }

        const request = {
            TagNames: [], // Skipping for now
            ActiveFilter: activeFilter.Active
        }
        listProducts(request, socket)
    }

    const initalizeAvailableProducts = (prodList) => {
        if (!prodList) {
            masterProductList = masterProductList
        }
        const availableProducts = prodList.filter(x => !comboCategory.Products.map(x => x.ProductId).includes(x.ProductId))
        setFilteredAvailableProducts(availableProducts.map(x => Object.assign({}, x))) // Just in case
    }

    const handleProductAdd = () => {
        const selectedProducts = filteredAvailableProducts.filter(x => x.Checked);
        if (selectedProducts) {
            const products = [...comboCategory.Products]
            selectedProducts.forEach(x => {
                products.push({
                    Name: x.Name,
                    ProductId: x.ProductId,
                })
            })
            updateItems(products)
        }
    }

    // Request
    const updateItems = (products, successUpdate) => {
        if (socket && !updateComboItemsLoading) {
            const productIds = products.map(x => x.ProductId)
            setUpdateComboItemsLoading(true)
            socket.props.setLoading = setUpdateComboItemsLoading
            socket.props.onUpdateComboItemsFailure = notify
            if (successUpdate) {
                socket.props.onUpdateComboItemsSuccess = successUpdate // Bad, but using the same fx for add/delete.  Definitely a better way to do this
            }
            else {
                socket.props.onUpdateComboItemsSuccess = (response) => {
                    // Update state, and the lists
                    setComboCategory(prevState => {
                        return {
                            ...prevState,
                            Products: products
                        }
                    })

                    const newAvailableProducts = [...filteredAvailableProducts].filter(x => !productIds.includes(x.ProductId))
                    setFilteredAvailableProducts(newAvailableProducts)
                    if (selectAllCheck) {
                        setSelectAllCheck(!selectAllCheck)
                    }
                }
            }

            const request = {
                ComboId: comboCategory.ComboId,
                ComboCategoryId: comboCategory.ComboCategoryId,
                ProductIds: productIds
            }
            updateComboItems(request, socket.socket)
        }
    }


    const handleProductListChange = (productId) => {
        let clone = filteredAvailableProducts.map(prod => (
            prod.ProductId === productId ? { ...prod, Checked: !prod.Checked } : prod
        ));
        setFilteredAvailableProducts(clone)
    }

    const removeProduct = (productId) => {
        // Probably can just extract the product and move it to the other list, instead of using a master list
        const newProducts = [...comboCategory.Products].filter(x => x.ProductId !== productId) || []
        const successUpdate = () => {
            setComboCategory(prevState => {
                return {
                    ...prevState,
                    Products: newProducts
                }
            })
            let newProductList = [...filteredAvailableProducts, masterProductList.filter(x => x.ProductId === productId)[0]].sort((a, b) => a.Name > b.Name ? 1 : -1)
            setFilteredAvailableProducts(newProductList)
        }
        updateItems(newProducts, successUpdate)
    }

    const handleSelectAll = (checked) => {
        let clone = filteredAvailableProducts.map(prod => (
            { ...prod, Checked: !checked }
        ))
        setFilteredAvailableProducts(clone)
        setSelectAllCheck(!selectAllCheck)
    }

    //TODO: This should be by ID.
    const filterProductsByCategory = (searchTerm) => {
        let filteredProducts = [];
        const selectedProductIds = comboCategory.Products.map(x => x.ProductId) || [];
        if (searchTerm !== 'All') {
            masterProductList.map(x => {
                if (x.Categories.includes(searchTerm) && !selectedProductIds.includes(x.ProductId)) {
                    filteredProducts.push(Object.assign({}, x))
                }
            })
        }
        else {
            masterProductList.map(x => {
                if (!selectedProductIds.includes(x.ProductId)) {
                    filteredProducts.push(Object.assign({}, x))
                }
            })
        }
        setFilteredAvailableProducts(filteredProducts)
    }


    const searchAvailableProduct = (searchTerm) => {
        // TODO: TagNames, not sure if we need partial names for tags, or I should be using a request here
        searchTerm = searchTerm.toLowerCase()
        const selectedProducts = comboCategory.Products.map(x => x.ProductId)
        const availableProducts = masterProductList.filter(x => !selectedProducts.includes(x.ProductId))
        const newProducts = availableProducts.filter(x => x.Name.toLowerCase().includes(searchTerm) || x.TagNames.map(y => y && y.Name.toLowerCase()).includes(searchTerm) || x.SizeDetails.toLowerCase().includes(searchTerm))
        setFilteredAvailableProducts(newProducts)
    }

    const updateProp = (prop, val) => {
        setComboCategory(prevState => {
            return { ...prevState, [prop]: val }
        })
    }

    const saveBaseComboCategory = (field) => {
        if(previousState[field] !== comboCategory[field]){
            updateForm && updateForm.current && updateForm.current.click()
        }
    }

    const updateCategory = (e) => {
        e.preventDefault()
        setUpdateCategoryLoading(true)
        socket.props.setUpdateCategoryLoading = setUpdateCategoryLoading
        socket.props.onUpdateComboCategoryFailure = notify
        socket.props.onUpdateComboCategorySuccess = () => {
            setPreviousState({...comboCategory})
        }
        const request = {
            ComboId: comboCategory.ComboId,
            ComboCategoryId: comboCategory.ComboCategoryId,
            Name: comboCategory.Name
        }
        socket && updateComboCategory(request, socket.socket)

    }



    return (
        <Fragment>
            <Row style={{ paddingBottom: '1rem' }}>
                <Col lg={1} />
                <Col>
                    <h4>
                        <ArrowBackIos style={{ fontSize: '2rem', cursor: 'pointer' }} onClick={() => back()} />
                        Edit Combo Items
                    </h4>
                </Col>
            </Row>
            {loading ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                </div>
                :
                comboCategory &&
                <Fragment>
                    <Form onSubmit={updateCategory} onKeyDown={(e) => e.target.key === 'Enter' ?  e.preventDefault() : '' }>
                        <Row style={{ paddingBottom: '2.5rem' }}>
                            <Form.Label column xs={4} md={2} style={{ textAlign: 'end' }}>
                                Category Name
                            </Form.Label>
                            <Col xs={8} md={4} lg={4} xl={3}>
                                <Form.Control required rows={3} value={comboCategory.Name} onChange={(e) => updateProp('Name', e.target.value)} onBlur={() => saveBaseComboCategory('Name')} />
                            </Col>
                        </Row>
                        <Button type="submit" ref={updateForm} disabled={updateCategoryLoading} style={{ display: 'none' }} />
                    </Form>

                    <Row>
                        <Col lg={1} />
                        <Col md={5} lg={5}>
                            <Card>
                                <CardHeader
                                    className={classes.cardHeader}
                                    title='Available Products'
                                />
                                <Row>
                                    <Col>
                                        <Form.Control
                                            style={{ width: 'auto', marginBottom: '1rem' }}
                                            as="select"
                                            value={filterAvailableDDL}
                                            onChange={(e) => { setFilterAvailbleDDL(e.target.value); filterProductsByCategory(e.target.value) }}
                                        >
                                            {/* Probably should be searching by ID, but all data is in names I think */}
                                            <option value={"All"}>All</option>
                                            {productCategories && productCategories.map(x => {
                                                return <option key={x.ProductCategoryId} value={x.Name}>{x.Name}</option>
                                            })}
                                        </Form.Control>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            placeholder='Search'
                                            value={searchTerm}
                                            onChange={e => { setSearchTerm(e.target.value); searchAvailableProduct(e.target.value) }}
                                        />
                                    </Col>
                                </Row>
                                <List className={classes.listContainer} dense component="div" role="list">
                                    <ListItem style={{ display: (filteredAvailableProducts && filteredAvailableProducts.count !== 0) ? 'flex' : 'none' }} dense button onClick={() => { setSelectAllCheck(!selectAllCheck); handleSelectAll(selectAllCheck); }}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                onChange={(e) => setSelectAllCheck(!selectAllCheck)}
                                                checked={selectAllCheck}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': "Select All" }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id="checkbox-list-label-SelectAll" primary="Select All" />
                                    </ListItem>
                                    {filteredAvailableProducts && filteredAvailableProducts.map((x) => {
                                        const labelId = `checkbox-list-label-${x.ProductId}`
                                        return (
                                            <ListItem key={x.ProductId} dense button onClick={() => handleProductListChange(x.ProductId)}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        onChange={(e) => handleProductListChange(x.ProductId)}
                                                        checked={x.Checked}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={x.Name} secondary={`(${x.SizeDetails}-${moneyFormat(x.Price)})`} />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </Card>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', paddingRight: '2.5rem', paddingTop: '1rem' }}>
                                <Button disabled={updateComboItemsLoading} onClick={() => handleProductAdd()}> Add Item(s)</Button>
                            </div>
                        </Col>
                        <Col md={5} lg={5}>
                            {/* Selected Products */}
                            <Card>
                                <CardHeader
                                    className={classes.cardHeader}
                                    title='Selected Products'
                                    style={{ paddingBottom: '6.4rem' }}
                                />
                                {/* TODO: May want a searchable enhancement for selectedProducts */}
                                <List className={classes.listContainer} dense component="div" role="list">
                                    {comboCategory.Products && comboCategory.Products.map((x) => {
                                        return (
                                            <ListItem key={x.ProductId} className={classes.listItem} role="listitem">
                                                <ListItemIcon>
                                                    <Delete color="error" style={{ cursor: 'pointer' }} className={classes.deleteIcon} onClick={() => removeProduct(x.ProductId)} />
                                                </ListItemIcon>
                                                <ListItemText id={`product-${x.ProductId}-label`} primary={`${x.Name}`} />
                                                {/* TODO */}
                                                {/* <ListItemText id={`product-${x.ProductId}-label`}  primary={x.Name} secondary={`(${x.SizeDetails}-${moneyFormat(x.Price)})`}/> */}
                                            </ListItem>
                                        )
                                    })}
                                    <ListItem />
                                </List>
                            </Card>
                        </Col>
                    </Row>
                </Fragment>

            }
        </Fragment>
    )

}