import { baseWS } from '../base'
import requestType from '../../enums/requestType'
import userType from '../../enums/userTypes'
import socketType from '../../enums/socketType'

import { 
    handleAuth, handleUpdateCustomer, handleViewCustomer, handleReloadQRCode, 
    handleChangeCustomerEmail, handleCustomerOrderUpdated, handleUpdateDefaultPayment
} from './customer/customerResponses'
import {
    handleViewStoreCategories, handleViewStoreInformation, handleViewStoreProducts,
    handleViewStoreProductDetail
} from './store/storeResponses'

import {
    handleAddToCart, handleCartRestore, handleGetToCart, handleStartCheckout, handleCardSubmit,
    handleRemoveFromCart, handleUpdateQuantity, handleApplyCoupon, handleRemoveCoupon, handleRemoveReward,
    handleApplyReward, handleTerminalCancel, handleAbortKey, handleSubmitCash, handleAddCartCombo, handleUpdateCartCombo, handleRemoveCartCombo
} from './cart/cartResponses'

import { handleCustomerHistory, handleCustomerHistoryDetail } from './order/orderResponses'
import { restoreCart } from './cart/cartRequests'
import { handleViewStoreCombo } from './combos/comboResponses'

const controller = `${baseWS}/store/v1`

export const connectToCustomerWS = (props, onOpenCallback) => {

    let socket = new WebSocket(controller)
    props.thisSocket = socket

    socket.onopen = (e) => {
        props.heartBeat = setInterval(() => {
            beatHeart(props)
        }, 10000)
        props.setSocketReconnecting(false)

        const storedCart = localStorage.getItem(`${props.storeId}_CART`)
        if (props.AccessToken) {
            const request = {
                RequestType: requestType.Auth,
                Data: {
                    UserType: userType.Customer,
                    AccessToken: props.AccessToken,
                    SocketType: socketType.Customer
                }
            }
            socket.send(JSON.stringify(request))
        }
        else if (storedCart) {
            const restoreData = {
                StoreAccountId: props.storeId,
                ShoppingCart: JSON.parse(storedCart)
            }
            props.restoreCartCallback = () => props.setDoneLoading(true)
            restoreCart(restoreData, socket)
        }
        else {
            props.setDoneLoading(true)
        }
        onOpenCallback && onOpenCallback(socket, props)
    }

    socket.onmessage = (e) => handleReceive(e, props)

    socket.onclose = (e) => {
        console.log('Socket Disconnected. Reconnecting now...', e.reason)
        // props.setSocketAuthed(false)
        props.setDoneLoading(false)
        props.setSocketReconnecting(true)
        clearInterval(props.heartBeat)
        setTimeout(() => {
            props.setSocket(connectToCustomerWS(props))
        }, 1000)
    }
    return { socket, props }
}

const handleReceive = (event, props) => {
    try {
        const response = JSON.parse(event.data)
        switch (response.RequestType) {
            case requestType.Auth:
                if (response.IsError) {
                    props.notify('Error', 'Error Connecting!')
                    props.setDoneLoading(true)
                }
                else {
                    //props.setSocketAuthed(true)

                    handleAuth(response, props)
                }
                break
            case requestType.ViewStoreCategories:
                handleViewStoreCategories(response, props)
                break
            case requestType.ViewStoreInformation:
                handleViewStoreInformation(response, props)
                break
            case requestType.ViewStoreProducts:
                handleViewStoreProducts(response, props)
                break
            case requestType.ViewStoreProductDetail:
                handleViewStoreProductDetail(response, props)
                break
            case requestType.AddToCart:
                handleAddToCart(response, props)
                break
            case requestType.RestoreCart:
                handleCartRestore(response, props)
                break
            case requestType.GetCart:
                handleGetToCart(response, props)
                break
            case requestType.Checkout:
                handleStartCheckout(response, props)
                break
            case requestType.CartSubmission:
                handleCardSubmit(response, props)
                break
            case requestType.RemoveFromCart:
                handleRemoveFromCart(response, props)
                break
            case requestType.UpdateCartQuantity:
                handleUpdateQuantity(response, props)
                break
            case requestType.ReloadQRCode:
                handleReloadQRCode(response, props)
                break;
            case requestType.ViewCustomer:
                handleViewCustomer(response, props)
                break
            case requestType.UpdateCustomer:
                handleUpdateCustomer(response, props)
                break
            case requestType.ApplyCoupon:
                handleApplyCoupon(response, props)
                break
            case requestType.RemoveCoupon:
                handleRemoveCoupon(response, props)
                break
            case requestType.ApplyReward:
                handleApplyReward(response, props)
                break
            case requestType.RemoveReward:
                handleRemoveReward(response, props)
                break
            case requestType.CustomerOrderList:
                handleCustomerHistory(response, props)
                break
            case requestType.CustomerOrderDetail:
                handleCustomerHistoryDetail(response, props)
                break
            case requestType.TerminalCancel:
                handleTerminalCancel(response, props)
                break
            case requestType.TerminalAbortKey:
                handleAbortKey(response, props)
                break
            case requestType.UpdateCustomerEmail:
                handleChangeCustomerEmail(response, props)
                break
            case requestType.SubmitCashOrder:
                handleSubmitCash(response, props)
                break
            case requestType.CustomerOrderUpdated:
                handleCustomerOrderUpdated(response, props)
                break
            case requestType.CustomerSetDefaultPayment:
                handleUpdateDefaultPayment(response, props)
                break
            case requestType.ViewStoreCombo:
                handleViewStoreCombo(response, props)
                break
            case requestType.AddCartCombo:
                handleAddCartCombo(response, props)
                break
            case requestType.UpdateCartCombo:
                handleUpdateCartCombo(response, props)
                break
            case requestType.RemoveCartCombo:
                handleRemoveCartCombo(response, props)
                break
            default:
                break
        }
    }
    catch (err) {
        console.log(err.toString())
    }
}

const beatHeart = (props) => {
    if (!props.thisSocket || props.thisSocket.readyState !== WebSocket.OPEN) {
        clearInterval(props.heartBeat)
    }
    else {
        props.thisSocket.send(JSON.stringify({
            RequestType: requestType.HeartBeat,
            Data: {}
        }))
    }
}