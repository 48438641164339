import React, { useState, useEffect, Fragment } from 'react'
import { Form, Row, Col, Button, Table } from 'react-bootstrap'
import CopyIcon from '@material-ui/icons/Assignment';
import { getAccountCustomerDetail, updateCustomerNote } from '../../api/sockets/account/accountRequests'
import { altUtcStringToLocal } from '../../utility/dateHelper'
import { makeStyles, useMediaQuery } from '@material-ui/core'
import userPages from '../../enums/userPages';
import { ArrowBackIos } from '@material-ui/icons';


const useStyles = makeStyles({
    clipBoard: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'lightgray'
        }
    },
})


export const AccountCustomerDetail = ({ props }) => {
    
    const { socket, notify, customerId, back, reloadCustomerList, setSelectedPage } = props
    const [loading, setLoading] = useState(true)
    const [customerDetail, setOrderDetail] = useState(null)
    const [note, setNote] = useState("")
    const isSmall = useMediaQuery('(max-width:700px)')

    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format
    const classes = useStyles()
    useEffect(() => {
        if (socket !== null && customerDetail === null) {
            reloadDetail()
        }
    }, [socket, customerDetail])


    const reloadDetail = () => {
        setLoading(true)

        socket.props.notify = notify
        socket.props.setLoading = setLoading
        socket.props.onDetailSuccess = (results) => {
            setOrderDetail(results)
            setNote(results.Note)
        }
        const requestData = {
            CustomerId: customerId
        }
        getAccountCustomerDetail(requestData, socket)
    }

    const sendCustomerNote = () => {
        socket.props.onUpdateFailure = () => {
            notify('Update Error', 'Error updating customer note')
        }

        const request = {
            CustomerId: customerId,
            Note: note
        }

        updateCustomerNote(request, socket.socket)
    }

    const copyToClipBoard = (field) => {
        navigator.clipboard.writeText(customerDetail[field])
        notify('Success', 'Copied to clipboard')
    }
    
    return (
        <Fragment>
            <Row style={{ marginBottom: '30px' }}>
                <Col xs={0} md={1}></Col>
                <Col>
                    <h2>
                        <ArrowBackIos style={{fontSize: '2rem', cursor: 'pointer'}} onClick={() => setSelectedPage(userPages.Customers)} />
                        Customer Detail
                    </h2>
                </Col>
            </Row>

            <Form.Group as={Row} style={{ alignItems: 'center', textAlign: 'end' }}>
                <Form.Label column xs={4} sm={2}>
                    First Name
                </Form.Label>
                <Col xs={7} sm={3}>
                    <Form.Control readOnly value={(customerDetail && customerDetail.FirstName) || ''} />
                </Col>
                    <CopyIcon className={classes.clipBoard} onClick={() => copyToClipBoard("FirstName")} color="primary"/>
                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Created
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(customerDetail && customerDetail.Created && altUtcStringToLocal(customerDetail.Created)) || ''} />
                </Col>
                <Col xs={0} md={1}></Col>
                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Last Name
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(customerDetail && customerDetail.LastName) || ''} />
                </Col>
                <CopyIcon className={classes.clipBoard} onClick={() => copyToClipBoard("LastName")} color="primary"/>
                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Note
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control 
                        as="textarea"
                        rows={3}
                        value={ note }
                        onChange={(e) => setNote(e.target.value)}
                        maxLength={1000}
                        style={{ resize: 'none' }}
                        onBlur={() => sendCustomerNote()}
                    />
                </Col>
                <Col xs={0} md={1}></Col>

                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Birthdate
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(customerDetail && customerDetail.Birthdate) || ''} />
                </Col>
                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px', marginLeft:  isSmall ? '0' : '1.5rem' }}>
                    Gender
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(customerDetail && customerDetail.Gender ? 'Male' : 'Female') || ''} />
                </Col>
                <Col xs={0} md={1}></Col>

                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px' }}>
                    Is Home Account
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(customerDetail && customerDetail.IsHomeAccount) || ''} />
                </Col>
                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px', marginLeft:  isSmall ? '0' : '1.5rem' }}>
                    Phone
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(customerDetail && customerDetail.Phone) || ''} />
                </Col>
                <CopyIcon className={classes.clipBoard} onClick={() => copyToClipBoard("Phone")} color="primary"/>
                <Col xs={0} md={1}></Col>

                <Col xs={4} md={2}>
                    Last Order
                </Col>
                <Col xs={7} md={3} style={{ textAlign: 'left' }}>
                    <Form.Control readOnly value={(customerDetail && customerDetail.LastOrder && altUtcStringToLocal(customerDetail.LastOrder)) || ''} />
                </Col>
                <Form.Label column xs={4} md={2} style={{ marginBottom: '10px',  marginLeft:  isSmall ? '0' : '1.5rem' }}>
                    Email
                </Form.Label>
                <Col xs={7} md={3}>
                    <Form.Control readOnly value={(customerDetail && customerDetail.Email) || ''} />
                </Col>
                <CopyIcon className={classes.clipBoard} onClick={() => copyToClipBoard("Email")} color="primary"/>

                <Col xs={0} md={1}></Col>

                <Col xs={4} md={2}></Col>
                <Col xs={7} md={3} style={{ textAlign: 'left' }}></Col>
                <Col xs={0} md={1}></Col>
            </Form.Group>

            <Row style={{ marginBottom: '30px' }}>
                <Col xs={0} md={1}></Col>
                <Col>
                    <h2>
                        Recent Orders
                    </h2>
                </Col>
            </Row>

            <Row style={{ marginBottom: '30px' }}>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Order No</th>
                            <th>Order Date</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customerDetail && customerDetail.RecentOrders && customerDetail.RecentOrders.map((x) => {
                                return (
                                    <tr key={x.OrderId} onClick={() => setSelectedPage(`${userPages.OrderDetail}${x.OrderId}|${customerDetail.CustomerId}|Y`)}>
                                        <td>{x.OrderNo}</td>
                                        <td>{x.OrderDate && altUtcStringToLocal(x.OrderDate)}</td>
                                        <td>{x.Amount && moneyFormat(x.Amount)}</td>
                                    </tr>
                                )
                            }
                        )}
                    </tbody>
                </Table>
            </Row>
        </Fragment>
    )
}