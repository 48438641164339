import React, { useEffect, Fragment, useState, useRef } from 'react'
import { CircularProgress, useMediaQuery, Typography, makeStyles } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Form, Button, Col, Row, Modal } from 'react-bootstrap'
import { viewUser, updateUser, changeUserPassword, updateUpline } from '../../api/sockets/user/userRequests'
import userType from '../../enums/userTypes'
import MaskedInput from 'react-text-mask'


const useStyles = makeStyles({
    visIcon: {
        position: 'absolute',
        top: '7px',
        right: '20px'
    },
    passwordInput: {
        minWidth: '80px',
        paddingRight: '35px'
    }
})

export const UserProfile = ({ socket, notify, setSelectedPage }) => {
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(false)
    const [priorState, setPriorState] = useState(null)
    const mainForm = useRef(null)

    // Passwords
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [hasEightChars, setHasEightChars] = useState(false)
    const [hasUppercase, setHasUppercase] = useState(false)
    const [hasLowercase, setHasLowercase] = useState(false)
    const [hasNumber, setHasNumber] = useState(false)
    const [newPasswordValid, setNewPasswordValid] = useState(false)
    const [viewCurrentPW, setViewCurrentPW] = useState(false)
    const [viewNewPW, setViewNewPW] = useState(false)

    // Link upline
    const [showLinkUpline, setShowLinkUpline] = useState(false)
    const [linkUplineEmail, setLinkUplineEmail] = useState(null)
    const [uplineFailureMsg, setUplineFailureMsg] = useState(null)
    const [uplineUpdateLoading, setUplineUpdateLoading] = useState(false)
    const isSmall = useMediaQuery('(max-width:768px)')
    const classes = useStyles()

    useEffect(() => {
        if (socket !== null) {
            reloadUserInfo()
        }
    }, [socket, setSelectedPage])


    const reloadUserInfo = () => {
        setLoading(true)
        socket.props.setLoading = setLoading
        // TODO: Socket keeps trying after failed request. 
        // Bogs down the system with all the notifies
        socket.props.onFailure = (message) => {
            notify(message)

        }

        socket.props.onSuccess = (response) => {
            setUser(response)
            setPriorState(response)
        }
        viewUser({}, socket.socket)
    }

    const updateProp = (val, field) => {
        const clone = Object.assign({}, user)
        clone[field] = val
        setUser(clone)
    }

    const saveUserDetails = (field) => {
        // May not need value here
        if (priorState[field] !== user[field]) {
            mainForm.current.click()
        }
    }

    const saveUser = (e) => {
        e.preventDefault()
        socket.props.onFailure = notify
        socket.props.onSuccess = () => {
            setPriorState(user)
        }
        
        const request = {
            FirstName: user.FirstName,
            LastName: user.LastName,
            Phone: user.Phone
        }
        updateUser(request, socket.socket)
    }

    const changePassword = (e) => {
        e.preventDefault()
        if (newPasswordValid) {
            socket.props.onPasswordChangeFailure = notify
            socket.props.onPasswordChangeSuccess = () => {
                notify("Password changed")
            }

            const request = {
                PreviousPassword: currentPassword,
                NewPassword: newPassword,
                UserType: userType.User
            }

            changeUserPassword(request, socket.socket)
        }
    }

    const linkUpline = (e) => {
        e.preventDefault()
        setUplineFailureMsg(null)
        setUplineUpdateLoading(true) 
        socket.props.onUplineFailure = (message) => {
            setUplineFailureMsg(message)
        }

        socket.props.onUplineSuccess = (data) => {
            const clone = Object.assign({}, user)
            clone.UplineEmail = data.UplineEmail
            clone.UplineFirstName = data.UplineFirstName
            clone.UplineLastName = data.UplineLastName
            setShowLinkUpline(false)
            setUplineUpdateLoading(false)
            setUser(clone)
            notify('Success', 'Upline information linked')
        }

        const request = {
            UplineEmail: linkUplineEmail,
            ResetUpline: false,
        }
        updateUpline(request, socket.socket)
    }

    const resetUpline = () => {
        
        socket.props.onUpdateFailure = notify
        socket.props.onUplineSuccess = () => {
            const clone = Object.assign({}, user)
            clone.UplineFirstName = ''
            clone.UplineLastName = ''
            clone.UplineEmail = ''
            setUser(clone)
            notify('Success','Your Upline was removed')
            
        }
        const request = {
            UplineEmail: user.UplineEmail,
            ResetUpline: true,
        }
        updateUpline(request, socket.socket)
    }
    
    const handlePWCheck = (pw) => {
        setHasEightChars(pw.length >= 8)
        setHasUppercase(pw.match(/[A-Z]/))
        setHasLowercase(pw.match(/[a-z]/))
        setHasNumber(pw.match(/\d/))
        setNewPasswordValid(hasEightChars && hasUppercase && hasLowercase && hasNumber)
    }


    return (
        <Fragment>
            {loading ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                </div> :
                user &&
                <div style={{paddingTop: '1.5rem'}}>
                    <Row>
                        <Col lg={1}></Col>
                        <Col>
                            <h2>Profile</h2>
                        </Col>
                        <div style={{flexDirection: 'row-reverse', paddingRight: isSmall ? '3rem' : '25rem'}}>
                            <Button disabled={user.UplineEmail} onClick={() => setShowLinkUpline(true)}>Link Upline</Button>
                            <Button variant="danger" disabled={!user.UplineEmail} style={{marginLeft: '1rem'}} onClick={() => resetUpline()}>Reset Upline</Button>    
                        </div>
                    </Row>
                    <hr />
                    <Form onSubmit={saveUser}>
                        <Form.Group as={Row}>
                            <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>First Name</Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <Form.Control value={user.FirstName || ''} required maxLength={200} onChange={(e) => updateProp(e.target.value, 'FirstName')} onBlur={() => saveUserDetails('FirstName')}></Form.Control>
                            </Col>
                            <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Last Name</Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <Form.Control value={user.LastName || ''} required maxLength={200} onChange={(e) => updateProp(e.target.value, 'LastName')} onBlur={() => saveUserDetails('LastName')}></Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Phone Number</Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <MaskedInput
                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                    className="form-control"
                                    guide={false}
                                    onBlur={() => saveUserDetails('Phone')}
                                    onChange={(e) => updateProp(e.target.value, 'Phone')}
                                    required
                                    type="tel"
                                    value={user?.Phone || ''}
                                />
                            </Col>
                            <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Upline First Name</Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <Form.Control value={user.UplineFirstName || ''} readOnly style={{caretColor: 'transparent', cursor: 'auto'}} maxLength={200}></Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Upline Last Name</Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <Form.Control value={user.UplineLastName || ''} readOnly style={{caretColor: 'transparent', cursor: 'auto'}} maxLength={200}></Form.Control>
                            </Col>
                            <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Upline Email</Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <Form.Control value={user.UplineEmail || ''} readOnly style={{caretColor: 'transparent', cursor: 'auto'}} maxLength={200}></Form.Control>
                            </Col>
                        </Form.Group>
                        <Button style={{ display: 'none' }} ref={mainForm} type="submit"></Button>
                    </Form>
                    <Row >
                        <Col lg={1}></Col>
                        <Col>
                            <h3>Change Password</h3>
                        </Col>
                    </Row>
                    <Form onSubmit={changePassword}>
                        <Form.Group as={Row}>
                            <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Current Password</Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <Form.Control
                                    required
                                    value={currentPassword}
                                    type={viewCurrentPW ? "text" : "password"}
                                    placeholder="Current Password"
                                    onChange={(e) => { setCurrentPassword(e.target.value) }}
                                    className={classes.passwordInput}
                                />
                                {viewCurrentPW
                                    ? <Visibility onClick={() => setViewCurrentPW(false)} className={classes.visIcon} />
                                    : <VisibilityOff onClick={() => setViewCurrentPW(true)} className={classes.visIcon} />
                                }
                            </Col>
                            <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>New Password</Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <Form.Control
                                    required
                                    value={newPassword}
                                    type={viewNewPW ? "text" : "password"}
                                    placeholder="New Password"
                                    onChange={(e) => { setNewPassword(e.target.value); handlePWCheck(e.target.value) }}
                                    className={classes.passwordInput}
                                />
                                {viewNewPW
                                    ? <Visibility onClick={() => setViewNewPW(false)} className={classes.visIcon} />
                                    : <VisibilityOff onClick={() => setViewNewPW(true)} className={classes.visIcon} />
                                }
                                <Typography style={{ color: hasEightChars ? 'green' : 'gray' }}>8 characters minimum</Typography>
                                <Typography style={{ color: hasUppercase ? 'green' : 'gray' }}>One uppercase letter</Typography>
                                <Typography style={{ color: hasLowercase ? 'green' : 'gray' }}>One lowercase letter</Typography>
                                <Typography style={{ color: hasNumber ? 'green' : 'gray' }}>One number</Typography>
                            </Col>
                            <Col xl={2} lg={3} md={3}>
                                <Button type="submit">Change Password</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                    <Modal show={showLinkUpline} onHide={() => setShowLinkUpline(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Link Upline Email</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={linkUpline}>
                                    {uplineFailureMsg && <p style={{color: 'red', fontSize: '.75rem' , paddingLeft: '6rem'}}>{uplineFailureMsg}</p>}
                                <Form.Group as={Row}>
                                    <Form.Label column xs={2} style={{textAlign:'right'}}>Email</Form.Label>
                                <Col>
                                    <Form.Control type="email" required value={linkUplineEmail || ''} onChange={(e) => {setLinkUplineEmail(e.target.value)}}></Form.Control>
                                </Col>
                                </Form.Group>
                                <Row style={{flexDirection:'row-reverse', paddingRight:'1rem'}}>
                                    <Button type="submit" disabled={uplineUpdateLoading} variant="success">Submit</Button>
                                </Row>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </div>
            }
        </Fragment>
    )
}