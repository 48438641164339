import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Form, ProgressBar, Button, ButtonGroup } from 'react-bootstrap'
import { baseURL } from '../../../api/base'
import { getTokensSyncNoUpdate } from '../../../api/auth/session'

const controller = `${baseURL}/content/v1`


export const StoreLogoUploader = ({ existingPath }) => {
    const [error, setError] = useState(null)
    const [fileName, setFileName] = useState('Select a Store Logo')
    const [uploadPercent, setUploadPercent] = useState(null)
    const [uploadDone, setUploadDone] = useState(true)
    const [filePath, setFilePath] = useState(existingPath)
    const tokenInfo = getTokensSyncNoUpdate()

    useEffect(() => {
        setFilePath(existingPath)
    }, [existingPath])

    const uploadImage = (e) => {
        const file = e.target.files[0]
        if (file === undefined) {
            return
        }

        setError(null)
        setUploadPercent(0)
        setUploadDone(false)
        const oneHundredMB = 104857600
        if (file.size > oneHundredMB) {
            setError('Maximum File Size is 100MB. Please Try Again.')
            return
        }

        let xhr = new XMLHttpRequest()
        if (xhr.upload) {
            xhr.upload.onprogress = (e) => {
                const done = e.position || e.loaded
                const total = e.totalSize || e.total
                setUploadPercent(Math.floor(done / total * 1000) / 10)
            }
        }
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                setUploadDone(true)
                setTimeout(() => setUploadPercent(null), 1000)
                const responseJSON = JSON.parse(xhr.response)

                if (!responseJSON.IsError) {
                    setFilePath(responseJSON.Path)
                    setFileName(file.name)
                }
                console.log(responseJSON)
            }
        }
        let formData = new FormData()
        formData.append('uploadFile', file)

        xhr.open('post', `${controller}/setaccountlogo`)
        xhr.setRequestHeader('Authorization', `bearer ${tokenInfo.IdToken}`)
        xhr.setRequestHeader('AccessToken', `${tokenInfo.AccessToken}`)
        xhr.send(formData)


    }

    return <Fragment>
        {error &&
            <Row>
                <Col xs={0} md={2}></Col>
                <Col>
                    <span style={{ color: 'red', fontWeight: 'bold' }}>{error}</span>
                </Col>
            </Row>
        }
        <Form.Group as={Row}>
            <Col>                
                <Form.File id={`prod-uploader-${tokenInfo.HomeAccountId}`} custom>
                    <Form.File.Input accept=".jpg, .jpeg, .gif, .png" disabled={!uploadDone} onChange={uploadImage} />
                    <Form.File.Label data-browse="Browse">
                        {fileName}
                    </Form.File.Label>
                </Form.File>
                {uploadPercent !== null &&
                    <ProgressBar animated now={uploadPercent} label={`${uploadPercent}%`} variant={uploadDone ? 'success' : 'info'} />
                }
                {filePath && (
                    <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                        <img src={`https://networksmarketer.com/images/${filePath}`} alt="Store Logo" width="300" />
                    </div>
                )}
            </Col>
        </Form.Group>
    </Fragment>

}