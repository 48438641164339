import React, { Fragment, useEffect, useState } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Nav, Navbar } from 'react-bootstrap'
import { clearCustomerSession, getCustomerTokens } from '../../api/auth/session'
import { ShoppingCart } from '@material-ui/icons'
import customerPages from '../../enums/customerPages'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
    badge: {
        '&:after': {
            position: 'absolute',
            right: '-20%',
            bottom: '-40%',
            content: 'attr(badgecount)',
            fontSize: '50%',
            padding: '.6em',
            borderRadius: '999px',
            lineHeight: '.55em',
            color: 'white',
            background: 'rgba(255,0,0,.85)',
            textAlign: 'center',
            minWidth: '2em',
            fontWeight: 'bold'
        }
    },
})

export const TopCustomerNav = ({ id, props }) => {
    const isSmall = useMediaQuery('(max-width:990px)');
    const isNotSmall = useMediaQuery('(min-width:990px)');
    
    const tokenInfo = getCustomerTokens()
    const classes = useStyles()
    const history = useHistory()

    const logOut = () => {
        clearCustomerSession(true)
        sessionStorage.removeItem('currentPage')
        history.push(window.location.pathname)
        localStorage.removeItem(`${id}_CART`)
        props.setItemsInCart(0)
        window.location.reload()
    }
    return (
        <div style={{ display: 'flex', position: 'absolute', flexDirection: 'column', top: '0px', zIndex: '99', marginBottom: isSmall ? '1rem' : '0' }}>
            <Navbar expanded={props.isNavBarExpanded} onToggle={() => props.setIsNavBarExpanded(!props.isNavBarExpanded)} collapseOnSelect expand="lg" style={{ backgroundColor: 'rgba(255, 255, 255, 0.95)', width: '100vw' }}>
                {isNotSmall && <div style={{ width: '15vw', textAlign: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', position: 'absolute', top: '8px', height: '4vh' }}>
                    <h5
                        style={{
                            textAlign: 'left', display: 'flex',
                            height: '100%', alignItems: 'center',
                            color: props.selectedPage === customerPages.storeInfo
                                ? 'green'
                                : 'black'
                        }}
                        onClick={() => props.setSelectedPage(customerPages.storeInfo)}
                    >
                        {props.storeInfo && props.storeInfo.Name}
                    </h5>
                </div>}
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                {isSmall &&

                    <Nav>
                        <Nav.Link
                            style={{
                                fontSize: '1.1rem',
                                color: props.selectedPage === customerPages.cart
                                    ? 'green'
                                    : 'black',
                            }}
                            onClick={() => props.setSelectedPage(customerPages.cart)}
                            eventKey={customerPages.cart}
                        >
                            <div>
                                <span style={{
                                    position: 'absolute',
                                    top: '.5rem',
                                    left: '5rem'
                                }}>
                                    <span className={props.itemsInCart ? classes.badge : ''} badgecount={props.itemsInCart}>
                                        <ShoppingCart />
                                    </span>
                                </span>
                            </div>
                        </Nav.Link>
                        <Nav.Link
                            style={{
                                fontSize: '1.1rem',
                                maxWidth: '60vw',
                                padding: '0px',
                                color: props.selectedPage === customerPages.viewCustomer || props.selectedPage === customerPages.createCustomer
                                    ? 'green'
                                    : 'black',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            onClick={() => props.setSelectedPage(tokenInfo ? customerPages.viewCustomer : customerPages.createCustomer)}
                        >
                            {tokenInfo ? tokenInfo.Email : 'Guest'}
                        </Nav.Link>

                    </Nav>
                }
                <Navbar.Collapse id="basic-navbar-nav" style={{ textAlign: 'center' }}>
                    <Nav className="ml-auto">
                        {!props.showLeftNav && props.categories && props.categories.map(x => {
                            const prodPage = `PRODUCT_CATEGORY_ID_${x.ProductCategoryId}`
                            return <Nav.Link
                                key={x.ProductCategoryId}
                                eventKey={x.ProductCategoryId}
                                style={{
                                    fontSize: '1.1rem',
                                    color: props.selectedPage === prodPage
                                        ? 'green'
                                        : 'black'
                                }}
                                onSelect={() => props.setSelectedPage(prodPage)}
                            >
                                {x.Name}
                            </Nav.Link>
                        })}
                        {props.notShowLeftNav && props.storeInfo &&
                            <Nav.Link
                                style={{
                                    fontSize: '1.1rem',
                                    color: props.selectedPage === customerPages.storeInfo
                                        ? 'green'
                                        : 'black'
                                }}
                                onClick={() => props.setSelectedPage(customerPages.storeInfo)}
                                eventKey={customerPages.storeInfo}
                            >
                                Store Information
                            </Nav.Link>
                        }
                        {isNotSmall &&
                            <Fragment>
                                <Nav.Link
                                    style={{
                                        fontSize: '1.1rem',
                                        color: props.selectedPage === customerPages.viewCustomer || props.selectedPage === customerPages.createCustomer
                                            ? 'green'
                                            : 'black'
                                    }}
                                    onClick={() => props.setSelectedPage(tokenInfo ? customerPages.viewCustomer : customerPages.createCustomer)}
                                >
                                    {tokenInfo ? tokenInfo.Email : 'Guest'}
                                </Nav.Link>
                                <Nav.Link
                                    style={{
                                        fontSize: '1.1rem',
                                        color: props.selectedPage === customerPages.cart
                                            ? 'green'
                                            : 'black'
                                    }}
                                    onClick={() => props.setSelectedPage(customerPages.cart)}
                                    eventKey={customerPages.cart}
                                >
                                    <div>
                                        <span style={{ position: 'relative' }}>
                                            <span className={props.itemsInCart ? classes.badge : ''} badgecount={props.itemsInCart}>
                                                <ShoppingCart />
                                            </span>
                                        </span>
                                    </div>
                                </Nav.Link>
                            </Fragment>
                        }
                        {tokenInfo && <Nav.Link style={{ fontSize: '1.1rem' }} onClick={() => { logOut() }}>Log Out</Nav.Link>}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}