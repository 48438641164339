import React, { useState, Fragment } from 'react'
import { Row, Col, } from 'react-bootstrap'
import { CreateCategory } from './createCategory'
import { makeStyles } from '@material-ui/core'

import AddCircleIcon from '@material-ui/icons/AddCircle'
import Tooltip from '@material-ui/core/Tooltip'
import { deleteComboCategory } from '../../../api/sockets/combos/comboRequests'
import {ListGroup } from 'react-bootstrap'
import EditIcon from '@material-ui/icons/Edit'
import { Delete } from '@material-ui/icons'


const useStyles = makeStyles({
    addButton: {
        cursor: 'pointer',
        color: 'green',
        '&:hover': {
            color: 'gray'
        }
    },
    editIcon: {
        cursor: 'pointer',
        '&:hover': {
            color: 'gray'
        },
        marginLeft: '1rem'
    },
    deleteIcon: {
        cursor: 'pointer',
        '&:hover': {
            color: 'gray'
        },
        marginLeft: '1rem'
    }
})


export const ComboCategories = ({ socket, notify, combo, setCombo, setComboCategory }) => {
    const [showCreate, setShowCreate] = useState(false)
    const [loading, setLoading] = useState(false)
    const classes = useStyles()


    const deleteCategory = (categoryId) => {
        if (!loading) {
            categoryId = parseInt(categoryId)
            setLoading(true) 
            socket.props.setLoading = setLoading
            socket.props.onDeleteComboCategoryFailure = notify
            socket.props.onDeleteComboCategorySuccess = () => {
                setCombo(prevState => {
                    return {
                        ...prevState,
                        Categories: prevState.Categories.filter(x => x.ComboCategoryId !== categoryId)
                    }
                })
            }
            const request = {
                ComboId: combo.ComboId,
                ComboCategoryId: categoryId
            }
            deleteComboCategory(request, socket.socket)
        }
    }



    return (
        <Fragment>
            <Row style={{ marginBottom: '10px' }}>
                <Col md={1} lg={2} />
                <Col>
                    <h4>
                        Categories
                        <Tooltip title="Add a category to your combo" placement="right" arrow>
                            <AddCircleIcon fontSize="default" color="inherit" className={classes.addButton} style={{ marginLeft: '.25rem' }} disabled={showCreate} onClick={() => setShowCreate(true)} />
                        </Tooltip>

                    </h4>

                </Col>
            </Row>
            {combo && combo.Categories &&
                <Row>
                    <Col md={2} />
                    <Col xl={8} style={{maxHeight: '60vh', overflowY:'scroll'}}>
                        <ListGroup>
                            {combo.Categories.map(x => {
                                return (
                                    <ListGroup.Item as="h5" style={{ paddingLeft: '1.5rem' }} key={x.ComboCategoryId}>{x.Name}
                                        <Tooltip title="Edit combo items" placement="top" arrow>
                                            <EditIcon color="primary" className={classes.editIcon} onClick={() => setComboCategory({...x, ComboId: combo.ComboId})} />
                                        </Tooltip>
                                        <Tooltip title="Delete category" placement="right" arrow>
                                            <Delete color="error" className={classes.deleteIcon} onClick={() => deleteCategory(x.ComboCategoryId)} />
                                        </Tooltip>
                                    </ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                    </Col>
                </Row>
            }
            <CreateCategory
                socket={socket}
                show={showCreate}
                setShow={setShowCreate}
                comboId={combo.ComboId}
                categories={combo.Categories}
                notify={notify}
            />
        </Fragment>
    )
}