import React, { useState, useEffect } from 'react'

import { Form } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { List, Card, CardHeader, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        padding: theme.spacing(1, 2),
        textAlign: 'center'
    },
    listContainer: {
        minWidth: 250,
        height: 250,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    listItem: {
        paddingTop: 10
    }
}))

export const AddonList = ({ props }) => {
    const classes = useStyles()
    const { setNotSelectedAddons, setSelectedAddons, allAddons, notSelectedAddons, selectedAddons } = props
    const [searchNotSelected, setSearchNotSelected] = useState('')
    const [filteredNotSelectedAddons, setFilteredNotSelectedAddons] = useState([])

    useEffect(() => {
        setFilteredNotSelectedAddons(
            notSelectedAddons.filter(x => x.Name.toLowerCase().includes(searchNotSelected.toLowerCase()))
        )
        
    }, [searchNotSelected, notSelectedAddons])

    const addToSelectedAddons = (ingredientId) => {
        let addon = allAddons.filter(x => x.IngredientId === ingredientId)
        let temp = notSelectedAddons.filter(x => x.IngredientId !== ingredientId)
        setNotSelectedAddons(temp)
        setSelectedAddons(selectedAddons.concat(addon))
    }

    return (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                title='Addon List'
            />
            <Form.Control placeholder='Search' onChange={e => setSearchNotSelected(e.target.value)} /> {/* TODO: Make this work */}
            <List className={classes.listContainer} dense component="div" role="list">
                {filteredNotSelectedAddons.map((x) => {
                    return (
                        <ListItem key={x.IngredientId} className={classes.listItem} role="listitem">
                            <ListItemIcon>
                                <AddCircleIcon color="primary" style={{cursor: 'pointer'}} onClick={() => addToSelectedAddons(x.IngredientId)} />
                            </ListItemIcon>
                            <ListItemText id={`addon-${x.IngredientId}-label`} primary={`${x.Name} (${x.Units})`} />
                        </ListItem>
                    )
                })}
                <ListItem />
            </List>
        </Card>
    )
}
