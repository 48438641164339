import React, { useEffect, useState, Fragment, useRef } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { updateCombo, viewComboDetail, updateComboProductCategories } from '../../../api/sockets/combos/comboRequests'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import CurrencyInput from '../../utility/currencyInput'
import { ComboImageUpdater } from './comboImageUpdater'
import { CircularProgress } from '@material-ui/core'
import { ComboCategories } from './comboCategories'
import { Input, MenuItem, FormControl, ListItemText, Select, Checkbox, makeStyles} from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
        maxWidth: 300,
        color: '#495057',
        backgroundColor: '#ffffff',
        backgroundClip: 'padding-box',
        border: '1px solid #ced4da',
        borderRadius: '.25rem',
        paddingLeft: '.5rem',
        height: 'calc(1.5em + .75rem + 2px)'
    },
    nounderline: {
        "&&&:before": {
            borderBottom: "none"
        },
        "&&:after": {
            borderBottom: "none"
        }
    },
    root: {
        margin: 'auto',
    },
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 15;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            minWidth: 250
        },
    },
    getContentAnchorEl: null
}




export const ComboDetail = ({ socket, comboId, notify, setComboId, setComboCategory,  productCategories }) => {
    const moneyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format
    const classes = useStyles()
    const isLarge = useMediaQuery('(min-width:1200px)')

    const [loading, setLoading] = useState(false)
    const [updateLoading, setUpdateLoading] = useState(false)
    const mainForm = useRef(null)

    const [combo, setCombo] = useState(null)
    const [previousState, setPreviousState] = useState(null)
    const [updateProductCategoryLoading, setUpdateProductCategoryLoading] = useState(false)

    useEffect(() => {
        if (socket) {
            if (!combo) {
                reloadComboDetail()
            }
        }
    }, [socket])



    const reloadComboDetail = () => {
        setLoading(true)
        socket.props.setLoading = setLoading
        socket.props.onViewComboDetailFailure = notify
        socket.props.onViewComboDetailSuccess = (response) => {
            const comboDetail = {
                ...response,
                Discount: moneyFormat(response.Discount), 
                Categories: response.Categories.sort((a, b) => a.Name > b.Name ? 1 : -1),
                ProductCategoryIds: response.ProductCategories.map(x => x.ProductCategoryId)

            }
            setCombo(comboDetail)
            setPreviousState({ ...comboDetail })
        }

        const request = {
            ComboId: comboId
        }
        viewComboDetail(request, socket.socket)

    }

    const updateProp = (prop, val) => {
        setCombo(prevState => {
            const updatedState = { ...prevState, [prop]: val }
            if (prop === 'Enabled') {
                saveBaseCombo('Enabled', updatedState)
            }
            return updatedState
        })
    }

    const saveBaseCombo = (prop, newComboState) => {
        if (!newComboState) {
            newComboState = combo
        }
        if (previousState[prop] !== newComboState[prop]) {
            setTimeout(() => mainForm && mainForm.current && mainForm.current.click(), 200)
        }
    }

    const saveProductCategories = () => {
        if((combo.ProductCategoryIds.some(x => !previousState.ProductCategoryIds.includes(x)) || previousState.ProductCategoryIds.some(x => !combo.ProductCategoryIds.includes(x))) && !updateProductCategoryLoading){
            socket.props.setUpdateProductCategoryLoading = setUpdateProductCategoryLoading
            socket.props.onUpdateComboProductCategoriesFailures = notify
            socket.props.onUpdateComboProductCategoriesSuccess = () => {
                setPreviousState({...combo})
            }
            const request = {
                ComboId: combo.ComboId,
                ProductCategoryIds: combo.ProductCategoryIds
            }
            socket && updateComboProductCategories(request, socket.socket)
        }
    }

    const validateAndUpdate = (e) => {
        e.preventDefault()
        setUpdateLoading(true)
        socket.props.onUpdateComboFailure = notify
        socket.props.setLoading = setUpdateLoading
        socket.props.onUpdateComboSuccess = () => {
            setPreviousState({ ...combo })
        }
        // Unmask the discount
        const discount = typeof (combo.Discount) === 'string' ? parseFloat(combo.Discount.replace('$', '')) : combo.Discount
        const request = {
            ComboId: combo.ComboId,
            Name: combo.Name,
            Description: combo.Description,
            Enabled: combo.Enabled,
            Discount: discount
        }


        socket && updateCombo(request, socket.socket)

    }

    return (
        <Fragment>
            <Row style={{ marginBottom: '10px' }}>
                <Col lg={1} />
                <Col>
                    <h4>
                        <ArrowBackIos style={{ fontSize: '2rem', cursor: 'pointer' }} onClick={() => setComboId(null)} />
                        Edit Combo
                    </h4>
                </Col>
            </Row>
            <Row style={{ marginBottom: '1rem' }}>
                <Col xs={1} md={2}></Col>
                <Col>
                    <h4>Details</h4>
                </Col>
            </Row>
            {loading
                ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                </div>
                :
                combo &&
                <Fragment>
                    {/* Probably need a better solution for this.  Enter key won't work on create category add and hurts UX */}
                    <Form onSubmit={validateAndUpdate} onKeyDown={(e) => e.key === 'Enter' ? e.preventDefault() : ''}>
                        <Form.Group as={Row}>
                            {/* Details */}
                            <Form.Label column xs={4} md={2} style={{ marginBottom: '10px', textAlign: 'end' }}>
                                Name
                            </Form.Label>
                            <Col xs={8} md={3} lg={4} xl={3}>
                                <Form.Control required value={combo?.Name || ''} onChange={(e) => updateProp('Name', e.target.value)} onBlur={() => saveBaseCombo('Name')} />
                            </Col>
                            <Form.Label column xs={4} md={2} style={{ textAlign: 'end' }}>
                                Description
                            </Form.Label>
                            <Col xs={8} md={4} lg={4} xl={3}>
                                <Form.Control as="textarea" required rows={3} value={combo?.Description || ''} maxLength={500} style={{ resize: 'none' }} onChange={(e) => updateProp('Description', e.target.value)} onBlur={() => saveBaseCombo('Description')} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column xs={4} md={2} style={{ marginBottom: '10px', textAlign: 'end' }}>
                                Discount
                        </Form.Label>
                            <Col xs={8} md={3} lg={4} xl={3}>
                                <CurrencyInput maskOptions={{ prefix: '$' }} guide={false} required className="form-control" value={combo.Discount} onChange={(e) => updateProp('Discount', e.target.value)} onBlur={() => saveBaseCombo('Discount')} />
                            </Col>
                            <Form.Label column htmlFor="enabledChk" xs={4} md={2} style={{ marginBottom: '10px', textAlign: 'end' }}>
                                Enabled
                        </Form.Label>
                            <Col xs={4} md={2} lg={4} xl={3} align="left">
                                <Form.Check id="enabledChk" type="switch" checked={combo?.Enabled || false} onChange={(e) => updateProp('Enabled', e.target.checked)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column xs={4} md={2} style={{ textAlign: 'end' }}>
                                Product Category
                            </Form.Label>
                            <Col xs={8} md={3} lg={4} xl={3} style={{ textAlign: 'left', paddingLeft: '.5rem' }}>
                                <FormControl className={classes.formControl}>
                                    {productCategories &&
                                        <Select
                                            multiple
                                            className={`${classes.nounderline}`}
                                            value={combo.ProductCategoryIds || ''}
                                            onChange={(e) => updateProp('ProductCategoryIds', e.target.value)}
                                            onClose={saveProductCategories}
                                            input={<Input placeholder="Select" />}
                                            renderValue={(selected) => {
                                                return productCategories.filter(x => selected.includes(x.ProductCategoryId)).map(x => x.Name).join(', ')
                                            }}
                                            MenuProps={MenuProps}
                                            displayEmpty
                                        >
                                            <MenuItem disabled>
                                                <em>Select Product Category</em>
                                            </MenuItem>
                                            {productCategories.map((x) => (
                                                <MenuItem key={x.ProductCategoryId} value={x.ProductCategoryId}>
                                                    <Checkbox checked={combo.ProductCategoryIds.indexOf(x.ProductCategoryId) > -1} />
                                                    <ListItemText primary={x.Name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    }
                                </FormControl>
                            </Col>
                            <Col xl={6} style={{ marginBottom: '1.5rem', marginBottom: '2rem' }}>
                                <ComboCategories
                                    socket={socket}
                                    combo={combo}
                                    notify={notify}
                                    setCombo={setCombo}
                                    setComboCategory={setComboCategory}
                                    productCategories={productCategories}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column xs={4} md={2} style={{ marginBottom: '10px', textAlign: 'end' }}>
                                Image
                            </Form.Label>
                            <ComboImageUpdater socket={socket} existingPath={combo.ImageLocation} comboId={combo.ComboId} />

                        </Form.Group>

                        <Row style={{ display: 'none' }}>
                            <Button ref={mainForm} disabled={updateLoading} type="submit" />
                        </Row>
                    </Form>
                </Fragment>
            }
        </Fragment>
    )


}