import React, { useEffect, Fragment, useState, useRef } from 'react'
import { CircularProgress, useMediaQuery, Typography, makeStyles, Tooltip } from '@material-ui/core'
import { Done as GoodIcon, Error as BadIcon, Visibility, VisibilityOff } from '@material-ui/icons'
import { Form, Button, Col, Row } from 'react-bootstrap'
import { viewCustomer, updateCustomer, changeCustomerEmail } from '../../../api/sockets/customer/customerRequests'
import { checkCustomerEmailExistsAPI } from '../../../api/customer/customer'
import { changeUserPassword } from '../../../api/sockets/user/userRequests'
import customerPages from '../../../enums/customerPages'
import StateDropdown from '../../utility/stateDropdown'
import { getCustomerTokens, modifyCustomerToken } from '../../../api/auth/session'
import userType from '../../../enums/userTypes'
import MaskedInput from 'react-text-mask'


const useStyles = makeStyles({
    visIcon: {
        position: 'absolute',
        top: '7px',
        right: '20px'
    },
    passwordInput: {
        minWidth: '80px',
        paddingRight: '35px'
    }
})

export const Profile = ({ socket, notify, setSelectedPage, storeId }) => {
    const [customer, setCustomer] = useState(null)
    const [priorState, setPriorState] = useState(null)
    const [loading, setLoading] = useState(false)
    const [addressIncluded, setAddressIncluded] = useState(false)
    const addressFields = ["Address", "Address1", "City", "State", "ZipCode"]
    const mainForm = useRef(null)
    const tokens = getCustomerTokens()
    
    // Passwords
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [hasEightChars, setHasEightChars] = useState(false)
    const [hasUppercase, setHasUppercase] = useState(false)
    const [hasLowercase, setHasLowercase] = useState(false)
    const [hasNumber, setHasNumber] = useState(false)
    const [newPasswordValid, setNewPasswordValid] = useState(false)
    const [viewCurrentPW, setViewCurrentPW] = useState(false)
    const [viewNewPW, setViewNewPW] = useState(false)

    // Email
    const [newEmail, setNewEmail] = useState('')
    const [isNewEmailGood, setIsNewEmailGood] = useState(null)

    const isSmall = useMediaQuery('(max-width:768px)')
    const classes = useStyles()

    useEffect(() => {
        if (socket !== null && !customer && socket.socket.readyState === WebSocket.OPEN) {
            reloadCustomerInfo()
        }
    }, [socket, notify])

    const reloadCustomerInfo = () => {
        setLoading(true)
        socket.props.onSuccess = (response) => {
            setCustomer(response)
            setPriorState(response)
            if (tokens?.NeedsInformation && !response.Phone) {
                notify('', 'Please enter a Phone number')
            }
        }
        socket.props.setLoading = setLoading
        socket.props.onFailure = (message) => {
            notify('Error', message)
            // Failure results in the socket to keep requesting.
            // Leave the page for now
            setSelectedPage(customerPages.storeInfo)
        }
        const requestData = {
            StoreAccountId: storeId
        }
        viewCustomer(requestData, socket.socket)
    }

    const saveCustomerDetails = (val, field) => {
        if (priorState[field] !== customer[field]) {
            mainForm.current.click()
        }
    }

    const updateProp = (val, field) => {
        const clone = Object.assign({}, customer)
        clone[field] = val
        if (addressFields.includes(field) && priorState[field] !== customer[field]) {
            // Include address update
            setAddressIncluded(true)
        }
        setCustomer(clone)
    }

    const updateInfo = (e) => {
        e.preventDefault()
        const request = {
            FirstName: customer.FirstName,
            LastName: customer.LastName,
            Phone: customer.Phone,
            Birthdate: customer.Birthdate,
            Gender: customer.Gender,
            AddressIncluded: addressIncluded,
            Address1: customer.Address1,
            Address2: customer.Address2,
            City: customer.City,
            State: customer.State,
            ZipCode: customer.ZipCode
        }
        socket.props.onSuccess = () => {
            setPriorState(customer)
            setAddressIncluded(false)
            if (customer.Phone && tokens?.NeedsInformation) {
                modifyCustomerToken('NeedsInformation', false)
            }
        }
        socket.props.onFailure = (message) => {
            notify(message)
            setAddressIncluded(false)
        }
        updateCustomer(request, socket.socket)
    }

    const changePassword = (e) => {
        e.preventDefault()
        if (currentPassword && newPassword && newPasswordValid) {
            socket.props.onPasswordChangeFailure = notify
            socket.props.onPasswordChangeSuccess = () => {
                notify("Password changed")
            }

            const request = {
                PreviousPassword: currentPassword,
                NewPassword: newPassword,
                UserType: userType.Customer
            }

            changeUserPassword(request, socket.socket)
        }
    }

    const handlePWCheck = (pw) => {
        setHasEightChars(pw.length >= 8)
        setHasUppercase(pw.match(/[A-Z]/))
        setHasLowercase(pw.match(/[a-z]/))
        setHasNumber(pw.match(/\d/))
        setNewPasswordValid(hasEightChars && hasUppercase && hasLowercase && hasNumber)
    }

    const changeEmail = () => {
        socket.props.onEmailChangeFailure = notify
        socket.props.onEmailChangeSuccess = () => {
            modifyCustomerToken('Email', newEmail)
            notify("Email changed")
        }

        const request = {
            NewEmail: newEmail
        }

        changeCustomerEmail(request, socket.socket)
    }

    const handleEmailCheck = async (e) => {
        e.preventDefault()
        setLoading(true)
        if (newEmail !== '') {
            let response
            let msg
            try {
                response = await checkCustomerEmailExistsAPI(newEmail)
            }
            catch (error) {
                let finalError = error
                try {
                    finalError = await error
                }
                catch (err) { }
                msg = finalError
            }
            if (response.Exists) {
                setIsNewEmailGood(false)
            }
            else {
                setIsNewEmailGood(true)
                changeEmail()
            }
        }
        else {
            setIsNewEmailGood(null)
        }
        setLoading(false)
    }


    return (
        <Fragment>
            {loading ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                </div> :
                customer &&
                <div>
                    <Row>
                        <Col lg={1}></Col>
                        <Col>
                            <h3>Profile</h3>
                        </Col>
                        <Col xs={5} lg={3} xl={2}>
                            <h4 style={{ color: 'gray' }}>
                                {customer.RewardPoints} Reward Points
                            </h4>
                        </Col>
                    </Row>
                    <hr />
                    <Form onSubmit={updateInfo}>
                        <Form.Group as={Row}>
                            <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>First Name</Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <Form.Control value={customer.FirstName} required maxLength={200} onChange={(e) => updateProp(e.target.value, 'FirstName')} onBlur={(e) => saveCustomerDetails(e.target.value, 'FirstName')}></Form.Control>
                            </Col>
                            <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Last Name</Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <Form.Control value={customer.LastName} required maxLength={200} onChange={(e) => updateProp(e.target.value, 'LastName')} onBlur={(e) => saveCustomerDetails(e.target.value, 'LastName')}></Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Phone Number</Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <MaskedInput
                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                    className="form-control"
                                    guide={false}
                                    onBlur={(e) => saveCustomerDetails(e.target.value ,'Phone')}
                                    onChange={(e) => updateProp(e.target.value, 'Phone')}
                                    required
                                    type="tel"
                                    value={customer?.Phone || ''}
                                />
                            </Col>
                            <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Birthdate</Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <Form.Control value={customer.Birthdate} maxLength={5} onChange={(e) => updateProp(e.target.value, 'Birthdate')} onBlur={(e) => saveCustomerDetails(e.target.value, 'Birthdate')}></Form.Control>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Gender</Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <Form.Control as="select" value={customer.Gender} onChange={(e) => updateProp(e.target.value, 'Gender')} onBlur={(e) => saveCustomerDetails(e.target.value, 'Gender')}>
                                    <option value={0}>-- Select Gender --</option>
                                    <option value={1}>Male</option>
                                    <option value={2}>Female</option>
                                    <option value={3}>Non-binary</option>
                                </Form.Control>
                            </Col>
                            <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Address</Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <Form.Control value={customer.Address1} maxLength={200} onChange={(e) => updateProp(e.target.value, 'Address1')} onBlur={(e) => saveCustomerDetails(e.target.value, 'Address1')} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Apt/Suite</Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <Form.Control value={customer.Address2} maxLength={200} onChange={(e) => updateProp(e.target.value, 'Address2')} onBlur={(e) => saveCustomerDetails(e.target.value, 'Address2')}></Form.Control>
                            </Col>
                            <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>City</Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <Form.Control value={customer.City} maxLength={200} onChange={(e) => updateProp(e.target.value, 'City')} onBlur={(e) => saveCustomerDetails(e.target.value, 'City')} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>State</Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <StateDropdown value={customer.State} onChange={updateProp} onBlur={saveCustomerDetails} propName='State'></StateDropdown>
                            </Col>
                            <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Zip Code</Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <Form.Control value={customer.ZipCode} maxLength={20} onChange={(e) => updateProp(e.target.value, 'ZipCode')} onBlur={(e) => { saveCustomerDetails(e.target.value, 'ZipCode') }} />
                            </Col>
                        </Form.Group>
                        <Button style={{ display: 'none' }} ref={mainForm} type="submit"></Button>
                    </Form>
                    <div style={{ display: tokens && tokens.IsSSO ? 'none' : 'block' }}>
                        <Row >
                            <Col lg={1}></Col>
                            <Col>
                                <h3>Change Password</h3>
                            </Col>
                        </Row>
                        <Form onSubmit={changePassword}>
                            <Form.Group as={Row}>
                                <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>Current Password</Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    <Form.Control
                                        required
                                        value={currentPassword}
                                        type={viewCurrentPW ? "text" : "password"}
                                        placeholder="Current Password"
                                        onChange={(e) => { setCurrentPassword(e.target.value) }}
                                        className={classes.passwordInput}
                                    />
                                    {viewCurrentPW
                                        ? <Visibility onClick={() => setViewCurrentPW(false)} className={classes.visIcon} />
                                        : <VisibilityOff onClick={() => setViewCurrentPW(true)} className={classes.visIcon} />
                                    }
                                </Col>
                                <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>New Password</Form.Label>
                                <Col xl={2} lg={3} md={3}>
                                    <Form.Control
                                        required
                                        value={newPassword}
                                        type={viewNewPW ? "text" : "password"}
                                        placeholder="New Password"
                                        onChange={(e) => { setNewPassword(e.target.value); handlePWCheck(e.target.value) }}
                                        className={classes.passwordInput}
                                    />
                                    {viewNewPW
                                        ? <Visibility onClick={() => setViewNewPW(false)} className={classes.visIcon} />
                                        : <VisibilityOff onClick={() => setViewNewPW(true)} className={classes.visIcon} />
                                    }
                                    <Typography style={{ color: hasEightChars ? 'green' : 'gray' }}>8 characters minimum</Typography>
                                    <Typography style={{ color: hasUppercase ? 'green' : 'gray' }}>One uppercase letter</Typography>
                                    <Typography style={{ color: hasLowercase ? 'green' : 'gray' }}>One lowercase letter</Typography>
                                    <Typography style={{ color: hasNumber ? 'green' : 'gray' }}>One number</Typography>
                                </Col>
                                <Col xl={2} lg={3} md={3}>
                                    <Button type="submit">Change Password</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    </div>
                    <Row >
                        <Col lg={1}></Col>
                        <Col>
                            <h3>Change Email</h3>
                        </Col>
                    </Row>
                    <Form onSubmit={handleEmailCheck}>
                        <Form.Group as={Row}>
                            <Form.Label column style={{ textAlign: isSmall ? 'left' : 'end' }} xl={2} lg={3} md={3}>New Email
                                {isNewEmailGood !== null && (isNewEmailGood
                                    ? <Tooltip title="Not Taken"><GoodIcon style={{ color: 'green' }} /></Tooltip>
                                    : <Tooltip title="This email address is already registered"><BadIcon style={{ color: 'red' }} /></Tooltip>
                                )}
                            </Form.Label>
                            <Col xl={2} lg={3} md={3}>
                                <Form.Control value={newEmail} required type="email" placeholder="Enter Email" onChange={(e) => { setNewEmail(e.target.value) }} />
                            </Col>
                            <Col xl={2} lg={3} md={3}></Col>
                            <Col xl={2} lg={3} md={3}>
                                <Button type="submit">Change Email</Button>
                            </Col>
                            <Col xl={2} lg={3} md={3}></Col>
                        </Form.Group>
                    </Form>
                </div>}
        </Fragment>
    )
}