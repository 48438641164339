import React, { useState, useEffect } from 'react'

import { Form, Alert, InputGroup } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles'
import { List, Card, CardHeader, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        padding: theme.spacing(1, 2),
        textAlign: 'center'
    },
    listContainer: {
        minWidth: 250,
        height: 250,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    listItem: {
        paddingTop: 10
    },
    listAction:{
        maxWidth: 100
    },
    fontLabel:{
        fontSize: '0.875rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1.43',
        letterSpacing: '0.01071em',
        marginBottom: '0px',
        color: 'rgba(0, 0, 0, 0.87)'
    }
}))


export const AddonSelectedList = ({ props }) => {
    const classes = useStyles()
    const { setNotSelectedAddons, setSelectedAddons, sendProductAddons,
            allAddons, notSelectedAddons, selectedAddons } = props

    const [searchSelected, setSearchSelected] = useState('')
    const [filteredSelectedAddons, setFilteredSelectedAddons] = useState([])
    const [errorAddons, setErrorAddons] = useState("")

    const numberMask = createNumberMask({
        prefix: '',
        suffix: '',
        requireDecimal: false,
        allowDecimal: true
    })

    useEffect(() => {
        setFilteredSelectedAddons(
            selectedAddons.filter(x => x.Name.toLowerCase().includes(searchSelected.toLowerCase()))
        )
    }, [searchSelected, selectedAddons])

    const removeFromSelectedAddons = (ingredientId) => {
        let ingredient = allAddons.filter(x => x.IngredientId === ingredientId)
        let temp = selectedAddons.filter(x => x.IngredientId !== ingredientId)
        setSelectedAddons(temp)
        setNotSelectedAddons(notSelectedAddons.concat(ingredient))
    }

    const updateAddonPrice = (newPrice, ingredientId) => {
        let newState = selectedAddons.map(x => x.IngredientId === ingredientId ? { ...x, Price: newPrice } : x)
        setSelectedAddons(newState)

        if (newState.some(x => x.Price === "" || x.Price === "0")) {
            let error = "";
            newState.forEach(x => {
                if (!x.Price) {
                    error += `${x.Name} has no price. \n`
                }
            })
            setErrorAddons(error)
        } 
        else {
            setErrorAddons("")
            sendProductAddons(newState)
        }
    }

    const updateAddonDefault = (ingredientId, newDefault) => {
        let newState = selectedAddons.map(x => x.IngredientId === ingredientId ? { ...x, DefaultOn: newDefault } : x)
        setSelectedAddons(newState)
        sendProductAddons(newState)
    }

    return (
        <Card>
            <CardHeader className={classes.cardHeader} title='Addons Added' />
            <Form.Control placeholder='Search' onChange={e => setSearchSelected(e.target.value)} />
            <List className={classes.listContainer} dense component="div" role="list">
                {errorAddons && <Alert style={{ whiteSpace: 'pre-line'}} variant="danger">{errorAddons}</Alert>}

                {filteredSelectedAddons.map((x) => {
                    return (
                        <ListItem 
                            key={x.IngredientId} 
                            className={classes.listItem} 
                            role="listitem"
                        >
                            <ListItemIcon>
                                <DeleteIcon style={{cursor: 'pointer'}} color="error" onClick={() => removeFromSelectedAddons(x.IngredientId)}/> 
                            </ListItemIcon>
                            <ListItemText 
                                id={`ingredient-${x.IngredientId}-label`}
                                primary={x.Name}
                                secondary={
                                    <div style={{display: 'flex', alignItems: 'center', paddingLeft: '15px'}}>
                                        <InputGroup size="sm" style={{width: '100px'}}>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text>$</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <MaskedInput
                                                className="form-control form-control-sm"
                                                mask={numberMask}
                                                style={{ display: 'inline-block' }}
                                                value={x.Price} 
                                                placeholder="0.00"
                                                onBlur={(e) => updateAddonPrice(e.target.value, x.IngredientId)} 
                                            />
                                        </InputGroup>
                                        <Typography component="span" variant="body2" color="textPrimary" style={{marginLeft: '5px'}}>
                                            Per {x.Units}
                                        </Typography>
                                        <Form style={{display: 'flex', alignItems: 'center', paddingLeft: '25px'}}>
                                            <Form.Label htmlFor={`defaultChk-${x.IngredientId}`} className={classes.fontLabel}>
                                                Default
                                            </Form.Label>
                                            <Form.Check 
                                                id={`defaultChk-${x.IngredientId}`}
                                                type="switch" 
                                                style={{marginLeft: '5px'}}
                                                checked={x.DefaultOn || false}
                                                onChange={(e) => updateAddonDefault(x.IngredientId, e.target.checked)}
                                            />
                                        </Form>
                                    </div>
                                }
                            />
                        </ListItem>
                    )
                })}
                <ListItem />
            </List>
        </Card>
    )
}
