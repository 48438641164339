import { CircularProgress } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { getCouponList } from '../../api/sockets/account/accountRequests'
import { CreateCoupon } from './createCoupon'
import { altUtcStringToLocal } from '../../utility/dateHelper'
import userPages from '../../enums/userPages'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
    rowHighlight: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'lightgray'
        }
    },
})

export const Coupons = ({socket, notify, props}) => {

    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [couponOverview, setCouponOverview] = useState(null)
    const [includeExpired, setIncludeExpired] = useState(false)

    const [showCreate, setShowCreate] = useState(false)

    useEffect(() => {
        if (couponOverview === null && socket){
            loadCoupons()
        }
    }, [])

    const loadCoupons = (overideInclude) => {
        setLoading(true)

        let reqInclude = overideInclude
        if (overideInclude === undefined){
            reqInclude = includeExpired
        }

        var requestData = {
            IncludeExpired: reqInclude
        }

        socket.props.setListLoading = setLoading
        socket.props.onListFailure = (msg) => {
            notify('List Error', msg)
        }
        socket.props.onListSuccess = (data) => {
            setCouponOverview(data.Coupons)
        }
        
        getCouponList(requestData, socket)
    }



    return <Fragment>
            <CreateCoupon show={showCreate} setShow={setShowCreate} socket={socket} notify={notify} props={{loadCoupons}} />
            <Row>
                <Col xs={0} md={1}></Col>
                <Col>
                    <h2>Coupons</h2>
                </Col>
                <Col xs={6} md={4}>
                    <Row>
                        <Col>
                            <Form.Control as="select" value={includeExpired} onChange={(e) => {setIncludeExpired(e.target.value); loadCoupons(e.target.value)}}>
                                <option value={false}>Hide Expired</option>
                                <option value={true}>Show Expired</option>
                            </Form.Control>
                        </Col>
                        <Col>
                            <Button onClick={() => setShowCreate(true)}>Create New</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr />
            {loading 
            ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%'}}>
                <CircularProgress style={{width: '100px', height: '100px'}} />
            </div>
            : <Fragment>
                <Row style={{fontWeight: 'bold', marginBottom: '20px'}}>
                    <Col>Name</Col>
                    <Col>Code</Col>
                    <Col>Start</Col>
                    {/* <Col>End</Col> */}
                </Row>
                {couponOverview && couponOverview.map((x, i) => {
                    return <Row key={i} style={{paddingTop: '5px', paddingBottom: '5px'}} onClick={() => props.setSelectedPageWithMemory(`${userPages.CouponDetail}|${x.CouponId}`)} className={classes.rowHighlight}>
                        <Col>{x.Name}</Col>
                        <Col>{x.Code}</Col>
                        <Col>{altUtcStringToLocal(x.Start)}</Col>
                        {/* <Col>{x.End || ''}</Col> */}
                    </Row>
                })}
            </Fragment>
            }
    </Fragment>
}