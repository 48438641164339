import React, { useState, useEffect } from 'react'

import { Form, Alert } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles'
import {List, Card, CardHeader, ListItem, ListItemIcon, ListItemText, Typography} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        padding: theme.spacing(1, 2),
        textAlign: 'center'
    },
    listContainer: {
        minWidth: 250,
        height: 250,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    listItem: {
        paddingTop: 10
    },
    listAction:{
        maxWidth: 100
    }
}))


export const IngredientSelectedList = ({ props }) => {
    const classes = useStyles()
    const { setNotSelectedIngredients, setSelectedIngredients, sendProductIngredients, allIngredients, notSelectedIngredients, selectedIngredients } = props
    const [searchSelected, setSearchSelected] = useState('')
    const [filteredSelectedIngredients, setFilteredSelectedIngredients] = useState([])
    const [errorIngredients, setErrorIngredients] = useState("")

    const numberMask = createNumberMask({
        prefix: '',
        suffix: '',
        requireDecimal: false,
        allowDecimal: true
    })

    useEffect(() => {
        setFilteredSelectedIngredients(
            selectedIngredients.filter(x => x.Name.toLowerCase().includes(searchSelected.toLowerCase()))
        )
    }, [searchSelected, selectedIngredients])

    const removeFromSelectedIngredients = (ingredientId) => {
        let ingredient = allIngredients.filter(x => x.IngredientId === ingredientId)
        let temp = selectedIngredients.filter(x => x.IngredientId !== ingredientId)
        setSelectedIngredients(temp)
        setNotSelectedIngredients(notSelectedIngredients.concat(ingredient))
        sendProductIngredients(temp)
    }

    const updateIngredientAmount = (newAmount, ingredientId) => {
        let newState = selectedIngredients.map(x => x.IngredientId === ingredientId ? { ...x, Amount: newAmount } : x)
        setSelectedIngredients(newState)
        sendProductIngredients(newState)
    }

    return (
        <Card>
            <CardHeader className={classes.cardHeader} title='Ingredients Added' />
            <Form.Control placeholder='Search' onChange={e => setSearchSelected(e.target.value)} />
            <List className={classes.listContainer} dense component="div" role="list">
                {errorIngredients && <Alert style={{ whiteSpace: 'pre-line'}} variant="danger">{errorIngredients}</Alert>}

                {filteredSelectedIngredients.map((x) => {
                    return (
                        <ListItem key={x.IngredientId} className={classes.listItem} role="listitem">
                            <ListItemIcon>
                                <DeleteIcon color="error" style={{cursor: 'pointer'}} onClick={() => removeFromSelectedIngredients(x.IngredientId)}/> 
                            </ListItemIcon>
                            <ListItemText 
                                id={`ingredient-${x.IngredientId}-label`} 
                                primary={x.Name}
                                secondary={
                                    <React.Fragment>
                                        
                                        <MaskedInput
                                            className="form-control form-control-sm"
                                            mask={numberMask}
                                            style={{ width: '100px', marginLeft:'15px', display: 'inline-block' }} 
                                            value={x.Amount} 
                                            placeholder="0.00"
                                            onBlur={(e) => updateIngredientAmount(e.target.value, x.IngredientId)} 
                                        />
                                        <Typography component="span" variant="body2" color="textPrimary" style={{marginLeft: '5px'}}>
                                            {x.Units}(s)
                                        </Typography>
                                    
                                        {x.Amount && (
                                            <Typography component="span" variant="body2" color="textPrimary" gutterBottom style={{ marginLeft:'25px', marginRight:'10px', display: 'inline-block' }} >
                                                {(x.Amount * x.VolumePointsPerUnit).toFixed(2)} VPs
                                            </Typography>
                                        )}
                                    </React.Fragment>
                                  }
                            />
                        </ListItem>
                    )
                })}
                <ListItem />
            </List>
        </Card>
    )
}
