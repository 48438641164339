import React, { useState, Fragment, useEffect } from 'react'
import { Row, Col, Form, Button, Modal } from 'react-bootstrap'
import { makeStyles, useMediaQuery, CircularProgress, Tooltip, TextField } from '@material-ui/core'
import { viewIngredientRenames, deleteIngredientRename, createIngredientRename, updateIngredientRename } from '../../api/sockets/account/accountRequests'
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { listIngredients } from '../../api/sockets/product/productRequests'
import sessionItems from '../../enums/sessionItems'
import { Autocomplete } from '@material-ui/lab'

const useStyles = makeStyles({
    stickyTH: {
        '& th': {
            position: 'sticky',
            top: '-2px',
            backgroundColor: 'lightgray',
            zIndex: 1
        }
    },
})

export const RenamedIngredients = ({ socket, notify}) => {
    const isXtraSmall = useMediaQuery('(max-width:525px)')
    const isBig = useMediaQuery('(min-width:1200px)')
    const isSmall = useMediaQuery('(max-width:1000px)') //IPad

    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    
    // Search 
    const [searchValue, setSearchValue] = useState('')
    const [filteredIngredients, setFilteredIngredientRenames] = useState([])
    
    // Confirm Delete
    const [show, setShow] = useState(false)
    const [deleteRenameId, setDeleteRenameId] = useState(null)
    
    // IngredientList
    const [ingredientList, setIngredientList] = useState(null)
    const [newRowId, setNewRowId] = useState(1)
    const [ingredientRenames, setIngredientRenames] = useState(null)
    const [previousState, setPreviousState] = useState(null)


    useEffect(() => {
        if (socket !== null) {
            if (ingredientRenames === null) {
                reloadIngredientRenames()
            }
            if (ingredientList === null) {
                reloadIngredients()
            }
        }
    }, [socket])

    
    const reloadIngredientRenames = () => {
        setLoading(true)
        socket.props.setLoading = setLoading
        socket.props.onViewIngredientRenamesFailure = (message) => {
            notify('Error', message)
        }
        socket.props.onViewIngredientRenamesSuccess = (response) => {
            setIngredientRenames(response)
            setFilteredIngredientRenames(response)
            setPreviousState(response.map(x => Object.assign({} ,x)))
        }
        viewIngredientRenames({}, socket.socket)
    }

    const reloadIngredients = () => {
        let ingredientsLocal = sessionStorage.getItem(sessionItems.IngredientList)
        if (ingredientsLocal) {
            setIngredientList(JSON.parse(ingredientsLocal))
        }
        else {
            setLoading(true)
            socket.props.notify = notify
            socket.props.setLoading = setLoading
            socket.props.onListIngredientsSuccess = (response) => {
                setIngredientList(response)
                sessionStorage.setItem(sessionItems.IngredientList, JSON.stringify(response))
            }
            socket.props.onListIngredientsFailure = () => {
                notify('Error', 'Error Loading Product Ingredients')
            }
            const requestData = {} // Currently there is no data for the request
            listIngredients(requestData, socket)
        }
    }

    const searchIngredient = (val) => {
        if(val !== ''){
            const ingredients = ingredientRenames.filter(x => x.OrigialName?.toLowerCase().includes(val.toLowerCase()) || x.NewName?.toLowerCase().includes(val.toLowerCase()))
            if (ingredients && ingredients.length > 0) {
                setFilteredIngredientRenames(ingredients)
            }
            else{
                setFilteredIngredientRenames([])
            }
        }
        else{
            setFilteredIngredientRenames(ingredientRenames)
        }
    }

    const handleDeleteClick = (ingredientRow) => {
        if (ingredientRow?.NewRowId != null) {
            let ingredients = ingredientRenames.filter(x => x.NewRowId !== ingredientRow.NewRowId)
            setIngredientRenames(ingredients)
            setFilteredIngredientRenames(ingredients)
            return
        }
        setDeleteRenameId(ingredientRow.RenameId)
        setShow(true)
    }

    const confirmDelete = () => {
        const renameId = deleteRenameId
        socket.props.onDeleteIngredientRenameFailure = (msg) =>{
            notify('Error Deleting Ingredient', msg)
        }
        socket.props.onDeleteIngredientRenameSuccess = () => {
            let newRenames = ingredientRenames.filter(x => x.RenameId !== renameId)
            setIngredientRenames(newRenames)
            setFilteredIngredientRenames(newRenames)
            setPreviousState(newRenames.map(x => Object.assign({}, x)))
        }
        const request = {
            RenameId: renameId
        }
        setShow(false)
        deleteIngredientRename(request, socket.socket)
        setDeleteRenameId(null)
    }

    const addNewRow = () => {
        let newIngredients = [...ingredientRenames, { NewRowId: newRowId }]
        setIngredientRenames(newIngredients)
        setFilteredIngredientRenames(newIngredients)
        setNewRowId(prevState => ++prevState)
    }

    const handleIngredientChange = (value, ingredient) => {
        // Handle New rows that don't have a rename Id
        if (ingredient?.NewRowId) {
            const ingredients = ingredientRenames.map(x => {
                if (x.NewRowId === ingredient.NewRowId && parseInt(value)) {
                    x.IngredientId = parseInt(value)
                }
                return x;
            })
            setIngredientRenames(ingredients)
            setFilteredIngredientRenames(ingredients)
            return
        }
        const ingredients = ingredientRenames.map(x => {
            if (x.RenameId === ingredient.RenameId) {
                x.IngredientId = parseInt(value)
            }
            return x
        })
        setIngredientRenames(ingredients)
        setFilteredIngredientRenames(ingredients)
    }

    const saveChanges = (ingredient) => {
        if (ingredient?.NewRowId && ingredient?.NewName && ingredient?.IngredientId) {
            createRename(ingredient)
        }
        else if (ingredient?.RenameId) {
            updateRename(ingredient)
        }
    }

    const updateRename = (ingredient) => {
        const previousName = previousState.filter(x => x.RenameId === ingredient.RenameId)[0]?.NewName
        if(previousName !== ingredient.NewName && ingredient.NewName !== ""){
            socket.props.onUpdateIngredientRenameFailure = (message) => {
                notify('Error Renaming Ingredient', message)
            }
            socket.props.onUpdateIngredientRenameSuccess = () => {
                notify('Success', 'Ingredient has been renamed')
                setPreviousState(ingredientRenames.map(x => Object.assign({} ,x)))
            }
            const request = {
                RenameId: ingredient.RenameId,
                NewName: ingredient.NewName
            }
            updateIngredientRename(request, socket.socket)
        }
    }


    const createRename = (ingredient) => {
        socket.props.onCreateIngredientRenameFailure = (message) => {
            notify('Error Renaming Ingredient', message)
        }
        socket.props.onCreateIngredientRenameSuccess = (response) => {
            // there has to be a better way
            // Since we can have multiple new rows, find the one in context
            notify('Success', 'Ingredient has been renamed')
            const ingredientClone = ingredientRenames.map(x => {
                if (x.NewRowId === ingredient.NewRowId) {
                    return {
                        RenameId: response.RenameId,
                        IngredientId: response.IngredientId,
                        NewName: response.Name,
                        OrigialName: ingredientList.filter(x => x.IngredientId === response.IngredientId)[0]?.Name
                    }
                }
                else {
                    return x
                }
            })
            setIngredientRenames(ingredientClone)
            setFilteredIngredientRenames(ingredientClone)
            setPreviousState(ingredientClone.map(x => Object.assign({}, x)))
        }

        const request = {
            IngredientId: ingredient.IngredientId,
            NewName: ingredient.NewName
        }
        createIngredientRename(request, socket.socket)
    }

    const handleNewNameChange = (val, ingredient) => {
        // Handle New Rows that don't have a rename Id
        if (ingredient?.NewRowId) {
            const ingredientClone = ingredientRenames.map(x => {
                if (x.NewRowId === ingredient.NewRowId) {
                    x.NewName = val
                }
                return x
            })
            setIngredientRenames(ingredientClone)
            return
        }
        const ingredientClone = ingredientRenames.map(x => {
            if (x.RenameId === ingredient.RenameId) {
                x.NewName = val
            }
            return x
        })
        setIngredientRenames(ingredientClone)
    }

    return (
        <Fragment>
            <Row style={{ alignItems: 'center', marginBottom: '10px' }}>
                <Col xs={0} md={1}></Col>
                <Col>
                    <h4 style={{ fontSize: isXtraSmall ? '1.3rem' : '1.5rem' }}>
                        Renamed Ingredients
                    </h4>
                </Col>
            </Row>
            <Row style={{ justifyContent: isXtraSmall ? 'flex-start' : 'flex-end', paddingBottom: '1.5rem', paddingTop: '1.5rem' }}>
                <Col xl={4} md={6} sm={8} xs={12} style={{ marginRight: isBig ? '5rem' : '0rem', paddingLeft: isXtraSmall ? '0' : 'inherit' }}>
                    <Form.Control placeholder="Search for an Ingredient" value={searchValue || ''} onChange={(e) => { setSearchValue(e.target.value); searchIngredient(e.target.value) }} />
                </Col>
            </Row>
            {loading
                ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                </div>
                : <Row>
                    <Col xs={12} sm={11} style={{ height: '65vh', overflowY: 'auto', paddingRight: 0 }}>
                        <table className="table table-striped table-sm table-hover" style={{ maxWidth: isBig ? '60vw' : '100vw', marginLeft: 'auto', marginRight: 'auto' }}>
                            <thead>
                                <tr className={classes.stickyTH}>
                                    <th>Ingredient</th>
                                    <th>New Name</th>
                                    <th style={{ backgroundColor: 'none', textAlign: 'center' }}>
                                        <Tooltip title="Add a Renamed Ingredient" placement="right">
                                            <AddIcon fontSize="large" style={{ color: 'green', cursor: 'pointer' }} onClick={() => addNewRow()} />
                                        </Tooltip>
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{ height: '4rem', overflow: 'scroll' }}>
                                {filteredIngredients && ingredientList && filteredIngredients.map((ingredient, i) => {
                                    let selectedIds = filteredIngredients.map(ing => ing.IngredientId)
                                    return (
                                        <tr key={i}>
                                            <td>
                                                {/* Autocomplete becomes part of Material ui core in 5.1.0 */}
                                                <Autocomplete
                                                    options={ingredientList}
                                                    // they check for undefined specifically and must equal the reference exactly for options value
                                                    value={ingredientList.find(x => x.IngredientId == ingredient.IngredientId) || undefined} 
                                                    getOptionLabel={(x) => `${x.Name} (${x.Units})` }
                                                    renderInput={(params) => <TextField {...params}  label="" variant="outlined" onBlur={(e, v) => saveChanges(v)} />}
                                                    // TODO: Fix currently selected being disabled
                                                    getOptionDisabled={(option) => selectedIds.includes(option.IngredientId)}
                                                    renderOption={(option) => (
                                                        <React.Fragment>
                                                          {option.Name} ({option.Units})
                                                        </React.Fragment>
                                                      )}
                                                    style={{ width: '30vw', fontSize: isSmall ? '.8rem' : '1rem', backgroundColor: 'white' }}
                                                    onChange={(e, v) => handleIngredientChange(v?.IngredientId, ingredient)}
                                                    disabled={Boolean(ingredient?.RenameId)}
                                                    autoHighlight
                                                />
                                            </td>
                                            <td>
                                                <Form.Control
                                                    value={ingredient.NewName || ''}
                                                    onChange={(e) => handleNewNameChange(e.target.value, ingredient)}
                                                    style={{ width: '20vw' }}
                                                    onBlur={(e) => saveChanges(ingredient)}
                                                />
                                            </td>
                                            <td style={{ textAlign: 'center' }}><DeleteIcon style={{ cursor: 'pointer' }} color="error" onClick={() => handleDeleteClick(ingredient)} /></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            }
            <Modal show={show} backdrop="static">
                <Modal.Header>
                    <Modal.Title>Delete Renamed Ingredient</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete Renamed Ingredient?
                    Deleting the Renamed Ingredient will revert the ingredient to it's original name.
                    </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShow(false)}>Cancel</Button>
                    <Button variant="danger" onClick={confirmDelete}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )

}