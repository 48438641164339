import { SIGN_OUT } from '../actions/user'


export default function products(state = {}, action) {
    switch (action.type){
        case SIGN_OUT:
            return {}
        default:
            return state
    }
}