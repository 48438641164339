import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    width: '15rem',
    height: 'auto'
  },
}));

export const HelpPopover = ({message}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'help' : undefined;

  return (
    <div style={{margin: '.5rem'}}>
      <HelpIcon onMouseEnter={handleClick} color="primary" />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onMouseOut={handleClose}
      >
        <p className={classes.typography}>{message}</p>
      </Popover>
    </div>
  );
}