export function convertTimeTo24(time12h) {
    const [time, modifier] = time12h.split(' ');
  
    let [hours, minutes] = time.split(':');
  
    if (hours === '12') {
      hours = '00';
    }
  
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
  
    return hours + ':' + minutes;
}
  
export function timespanToMins(timespan){
    try {
      let modified = ''
      let extra = 0
      if (timespan.includes('.')){
        const daysplit = timespan.split('.')
        modified = daysplit[1]
        extra = Number(daysplit[0] * 60 * 24)
      }
      else
        modified = timespan
      const sections = modified.split(':')
      return Number((sections[0]*60))+Number(sections[1]) + Number(extra)
    }
    catch {
      return 0
    }
}
  
export function minsToTimeSpan(mins){
    if (mins === '')
      mins = 0
    let hours = Math.floor(mins / 60)
    let days = Math.floor(hours / 24)
    let remainder = Math.floor(mins % 60)
    return `${days}.${hours}:${remainder}:00`
}
  
export const utcStringToLocal = (utcString, format = "MM/dd/yyyy") => {
    if (!utcString){
      return ''
    }
    utcString = utcString + ' UTC'
    let date = new Date(utcString)
  
    // Now Output
    if (format === 'MM/dd/yyyy'){
      return date.toLocaleDateString('en-US')
    }
    else if (format === 'datetime'){
      return date.toLocaleString()
    }
    else{
      return date.toLocaleDateString()
    }
}

export const altUtcStringToLocal = (utcString, format) => {
  if (!utcString){
    return ''
  }
  let newDate = new Date(utcString).toLocaleString()
  if (newDate === 'Invalid Date'){
    return ''
  }
  newDate = newDate + ' UTC'
  let date = new Date(newDate)
  if (format && format === 'MM/dd/yyyy'){
    return date.toLocaleDateString('en-US')
  }
  else{
    return date.toLocaleString()
  }
}
  
export const utcStringToLocalDate = (utcString) => {
    utcString = utcString + ' UTC'
    let date = new Date(utcString)
    return date
}
  
export const addBusinessDays = (start, days) => {
    start = new Date(start)
    while(days > 0){
      const dow = start.getDay()
      if (dow === 6 || dow === 5){
        start.setDate(start.getDate() + 1)
        continue
      }
      start.setDate(start.getDate() + 1)
      days--
    }
    return start
}