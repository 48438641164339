import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CardActionArea, CardMedia, CardContent, Grid, Typography } from '@material-ui/core'
import { productImageBase } from '../../api/base'
import { Fragment } from 'react'

const useStyles = makeStyles({
    root: {
        width: '20vh',
        height: '100%',
        boxShadow: '#cac7c7 0px 0px 5px 1px',
        borderRadius: '6px',
        backgroundColor: '#eeeeee',
        margin: '10px 10px 10px 10px'
    },
    media: {
        height: 140,
        backgroundSize: 'contain'
    },
})

// These need categorized some how
export const ComboCard = ({ combo, isSmall, setComboId }) => {
    const classes = useStyles()
    const defaultImageLocation = 'missingimage.jpg' // May not need this card, but I did see it in the DB as an image
    let modifiedLocation = combo.ImageLocation
    if (modifiedLocation) {
        modifiedLocation = `${productImageBase}${modifiedLocation}`
    }
    return (
        <Fragment>
            <CardActionArea
                onClick={() => setComboId(combo.ComboId)}
                style={{
                    backgroundColor: 'rgb(228 229 232 / 50%)',
                    borderRadius: '10px'
                }}
            >
                <CardMedia
                    className={classes.media}
                    image={modifiedLocation || defaultImageLocation}
                    title={combo.Name}
                    style={{ marginTop: '5px', opacity: !modifiedLocation ? '0.25' : null }}
                />
                <CardContent>
                    <Grid container spacing={2} style={{ overflowWrap: 'normal' }}>
                        <Grid item xs={12} md={8}>
                            <Typography variant="h5" style={{ fontSize: isSmall ? '1rem' : '1.5rem' }}>{combo.Name}</Typography>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Typography variant="body2" style={{ color: combo.Enabled ? "green" : "red" }} component="p">
                                {combo.Enabled ? "Enabled" : "Disabled"}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Fragment>
    )
}