import React, { useState, Fragment, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Col, Row, ProgressBar } from 'react-bootstrap'
import { getDailySnapshot } from '../../api/sockets/product/productRequests'

const useStyles = makeStyles({
    tableRow: {
        borderBottom: "solid 1px darkgrey",
        height: "fit-content",
        padding: "15px"
    },
    overviewData: {
        fontSize: "2rem",
        fontWeight: 600
    },
    overviewLabel: {
        fontSize: ".75rem"
    }
})

export const ViewDailySnapshotReport = ({ socket, notify }) => {
    const classes = useStyles()

    const [snapshotData, setSnapshotData] = useState(null)
    const [cashPercent, setCashPercent] = useState(0)
    const [cardPercent, setCardPercent] = useState(0)
    const [giftCardPercent, setGiftCardPercent] = useState(0)


    useEffect(() => {
        if (!snapshotData && socket) {
            window.dashboard = null
            loadDailySnapshot()
        }
    }, [])

    const loadDailySnapshot = () => {
        socket.props.setLoading = null
        socket.props.onSnapshotSuccess = (data) => {
            setSnapshotData(data)
            if (data.TotalSales > 0)
            {
                setCashPercent((data.Cash / data.TotalSales) * 100)
                setCardPercent((data.Card / data.TotalSales) * 100)
                setGiftCardPercent((data.GiftCard / data.TotalSales) * 100)
            }
        }
        socket.props.onSnapshotFailure = (msg) => {
            notify('Error', 'Error Loading Snapshot')
        }

        getDailySnapshot(socket)
    }

    return <Fragment>
        <Row>
            <Col>
                <h2>
                    Daily Snapshot
                </h2>
            </Col>
        </Row>
        <hr />
        <Row style={{ marginTop: '5px', height: '85%' }}>
            <Col lg={12}>
                <Row>
                    <Col lg={12} className={classes.tableRow} style={{ textAlign: "center" }}>
                        Sales Summary: Overview
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Row>
                            <Col xs={6} sm={12} md={12} lg={6}>
                            <Row>
                                <Col xs={12} sm={4} md={4} lg={4}>
                                    <Row className={classes.overviewData}>
                                        {snapshotData && snapshotData.GrossSales && `$${snapshotData.GrossSales.toFixed(2)}`}
                                    </Row>
                                    <Row className={classes.overviewLabel}>
                                        Gross Sales
                                </Row>
                                </Col>
                                <Col xs={12} sm={4} md={4} lg={4}>
                                    <Row className={classes.overviewData}>
                                        {snapshotData && snapshotData.NetSales && `$${snapshotData.NetSales.toFixed(2)}`}
                                    </Row>
                                    <Row className={classes.overviewLabel}>
                                        Net Sales
                                </Row>
                                </Col>
                                <Col xs={12} sm={4} md={4} lg={4}>
                                    <Row className={classes.overviewData}>
                                        {snapshotData && `${snapshotData.SaleCount}`}
                                    </Row>
                                    <Row className={classes.overviewLabel}>
                                        Sale Count
                                </Row>
                                </Col>
                                </Row>
                            </Col>
                            <Col xs={6} sm={12} md={12} lg={6}>
                                <Row>
                                <Col xs={12} sm={4} md={4} lg={4}>
                                    <Row className={classes.overviewData}>
                                        {snapshotData && snapshotData.SaleAverage && `$${snapshotData.SaleAverage.toFixed(2)}`}
                                    </Row>
                                    <Row className={classes.overviewLabel}>
                                        Sale Average
                                </Row>
                                </Col>
                                <Col xs={12} sm={4} md={4} lg={4}>
                                    <Row className={classes.overviewData}>
                                        {snapshotData && snapshotData.Refunds && `$${snapshotData.Refunds.toFixed(2)}`}
                                    </Row>
                                    <Row className={classes.overviewLabel}>
                                        Refunds
                                </Row>
                                </Col>
                                <Col xs={12} sm={4} md={4} lg={4}>
                                    <Row className={classes.overviewData}>
                                        {snapshotData && snapshotData.Discounts && `$${snapshotData.Discounts.toFixed(2)}`}
                                    </Row>
                                    <Row className={classes.overviewLabel}>
                                        Discounts
                                </Row>
                                </Col>
                                </Row>
                                
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Col>
            <Col lg={6}>
                <Row>
                    <Col lg={12} className={classes.tableRow} style={{ textAlign: "center" }}>
                        Sales by Payment Type
                    </Col>
                    <Col lg={12} className={classes.tableRow} style={{ fontWeight: "bold" }}>
                        <Row>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "left" }}>
                                Total Collected
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "right" }}>
                                {snapshotData && snapshotData.TotalSales && `$${snapshotData.TotalSales.toFixed(2)}`}
                            </Col>
                        </Row>
                        <ProgressBar>
                            <ProgressBar variant="info" now={cardPercent} key={1} />
                            <ProgressBar variant="success" now={cashPercent} key={2} />
                            <ProgressBar variant="warning" now={giftCardPercent} key={3} />
                        </ProgressBar>
                    </Col>
                    <Col lg={12} className={classes.tableRow}>
                        <Row>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "left" }}>
                                Card
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "right" }}>
                                {snapshotData && snapshotData.Card && `$${snapshotData.Card.toFixed(2)}`}
                            </Col>
                        </Row>
                        <ProgressBar variant="info" now={cardPercent} />
                    </Col>
                    <Col lg={12} className={classes.tableRow}>
                        <Row>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "left" }}>
                                Cash
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "right" }}>
                                {snapshotData && snapshotData.Cash && `$${snapshotData.Cash.toFixed(2)}`}
                            </Col>
                        </Row>
                        <ProgressBar variant="success" now={cashPercent} />
                    </Col>
                    <Col lg={12} className={classes.tableRow}>
                        <Row>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "left" }}>
                                Gift Card
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "right" }}>
                                {snapshotData && snapshotData.GiftCard && `$${snapshotData.GiftCard.toFixed(2)}`}
                            </Col>
                        </Row>
                        <ProgressBar variant="warning" now={giftCardPercent} />
                    </Col>
                    <Col lg={12} className={classes.tableRow}>
                        <Row>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "left" }}>
                                Fees
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "right" }}>
                                {snapshotData && snapshotData.Fees && `$${snapshotData.Fees.toFixed(2)}`}
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={12} className={classes.tableRow}>
                        <Row>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "left" }}>
                                Net Collected
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "right" }}>
                                {snapshotData && snapshotData.NetCollected && `$${snapshotData.NetCollected.toFixed(2)}`}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col lg={6}>
                <Row>
                    <Col lg={12} className={classes.tableRow} style={{ textAlign: "center" }}>
                        Sales Summary: Detail
                    </Col>
                    <Col lg={12} className={classes.tableRow}>
                        <Row>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "left" }}>
                                Gross Sales
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "right" }}>
                                {snapshotData && snapshotData.GrossSales && `$${snapshotData.GrossSales.toFixed(2)}`}
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={12} className={classes.tableRow}>
                        <Row>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "left" }}>
                                Refunds
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "right" }}>
                                {snapshotData && snapshotData.Refunds && `$${snapshotData.Refunds.toFixed(2)}`}
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={12} className={classes.tableRow}>
                        <Row>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "left" }}>
                                Discounts
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "right" }}>
                                {snapshotData && snapshotData.Discounts && `$${snapshotData.Discounts.toFixed(2)}`}
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={12} className={classes.tableRow}>
                        <Row>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "left" }}>
                                Net Sales
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "right" }}>
                                {snapshotData && snapshotData.NetSales && `$${snapshotData.NetSales.toFixed(2)}`}
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={12} className={classes.tableRow}>
                        <Row>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "left" }}>
                                Tax
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "right" }}>
                                {snapshotData && snapshotData.Tax && `$${snapshotData.Tax.toFixed(2)}`}
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={12} className={classes.tableRow}>
                        <Row>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "left" }}>
                                Tips
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "right" }}>
                                {snapshotData && snapshotData.Tips && `$${snapshotData.Tips.toFixed(2)}`}
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={12} className={classes.tableRow}>
                        <Row>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "left" }}>
                                Total Paid
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} style={{ textAlign: "right" }}>
                                {snapshotData && snapshotData.TotalPaid && `$${snapshotData.TotalPaid.toFixed(2)}`}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>

        </Row>
    </Fragment>
}
