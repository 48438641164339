import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { productImageBase } from '../../api/base'
import { Col } from 'react-bootstrap'
import { Fragment } from 'react'

const useStyles = makeStyles({
    root: {
        width: '20vh',
        height: '100%',
        boxShadow: '#cac7c7 0px 0px 5px 1px',
        borderRadius: '6px',
        backgroundColor: '#eeeeee',
        margin: '10px 10px 10px 10px'
    },
    media: {
        height: 140,
        backgroundSize: 'contain'
    },
})

export const ProductCard = ({ product, setShowProductId, isSmall }) => {
    const classes = useStyles()
    const defaultImageLocation = 'missingimage.jpg'
    let modifiedLocation = product.ImageLocation
    if (modifiedLocation) {
        modifiedLocation = `${productImageBase}${modifiedLocation}`
    }
    
    return (
        <Fragment>
            <CardActionArea
                onClick={() => setShowProductId(product.ProductId)}
                style={{
                    backgroundColor: 'rgb(228 229 232 / 50%)',
                    borderRadius: '10px'
                }}
            >
                <CardMedia
                    className={classes.media}
                    image={modifiedLocation || defaultImageLocation}
                    title={product.Name}
                    style={{ marginTop: '5px', opacity: !modifiedLocation ? '0.25' : null }}
                />
                <CardContent>
                    <Grid container spacing={2} style={{ overflowWrap: 'normal' }}>
                        <Grid item xs={12} md={8}>
                            <Typography variant="h5" style={{ fontSize: isSmall ? '1rem' : '1.5rem' }}>{product.Name}</Typography>
                            <Typography variant="body2" style={{ fontStyle: 'italic' }} component="p">{product.SizeDetails}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <Typography variant="h5" style={{ fontSize: isSmall ? '1rem' : '1.5rem' }} align="right">${product.Price.toFixed(2)}</Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {product.TagNames.map(x => x.Name).join(", ")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Typography variant="body2" style={{ color: product.Enabled ? "green" : "red" }} component="p">
                                {product.Enabled ? "Enabled" : "Disabled"}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Fragment>
    )
}