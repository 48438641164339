// This is going to mimic the added to cart splash screen for products
// But will show show all of the combo products added

import React, { Fragment, useState, useEffect } from 'react'
import { Button, Card, Col, Row, Container } from 'react-bootstrap'
import { loadProducts } from '../../../api/sockets/store/storeRequests'
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { Done as GoodIcon } from '@material-ui/icons'
import { productImageBase } from '../../../api/base'
import { CartDetail } from '../../customer/cartSplash/cartDetail'
import { RelatedProduct } from '../../customer/cartSplash/relatedProduct'
import {getCustomerTokens} from '../../../api/auth/session'
import customerPages from '../../../enums/customerPages'


const useStyles = makeStyles({
    backdrop: {
        backgroundColor: 'transparent',
    },
    modalWrapper: {
        marginTop: '5vh',
        marginLeft: 'auto', 
        marginRight: 'auto', 
    }
})

export const StoreComboAddSplash = ({ lastCategory, storeId, socket, notify, setSelectedPage, setIsNavBarExpanded, addedItems }) => {
    const isSmall = useMediaQuery('(max-width:525px)')

    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [relatedProducts, setRelatedProducts] = useState(null)
    const defaultImageLocation = '/missingimage.jpg'

    

    useEffect(() => {
        if (socket !== null) {
            // Load related Products possibly
        }

    }, [socket])

    
    const checkoutClick = () => {
        const tokens = getCustomerTokens()
        if (tokens && !tokens.NeedsInformation) {
            setSelectedPage(customerPages.cart)
        }
        else if (tokens?.NeedsInformation){
            notify('Warning', `Please enter your phone number so we can complete your order.`)
            setSelectedPage(customerPages.viewCustomer)
        }
        else {
            notify('Warning', 'Please sign in to complete your order')
            setSelectedPage(customerPages.createCustomer)
        }
    }

    const continueShoppingClick = () => {
        if (isSmall){
            setIsNavBarExpanded(true)
        }
        setSelectedPage(lastCategory)
    }

    return (
        <Container style={{marginTop: isSmall ? '1.5rem' : 'inherit'}}>
            <Row style={{alignItems: 'center'}}>
                {!isSmall && <GoodIcon style={{color: 'green', fontSize: '2rem', marginRight: '30px'}} />}
                <h2>
                    {addedItems && addedItems.length} Items were added to your cart!
                </h2>
            </Row>
            <hr />
            {addedItems && addedItems.map((x, i) => {
                let modifiedLocation = x?.ImageLocation
                if (modifiedLocation) {
                    modifiedLocation = `${productImageBase}${modifiedLocation}`
                }
                return <Row style={{ paddingBottom: '1.5rem' }}>
                    <Col md lg={3} xl style={{marginBottom: '10px'}}>
                        {modifiedLocation 
                            ? <Card.Img style={{ maxWidth: '150px', height: 'auto' }} src={modifiedLocation || defaultImageLocation} />
                            : (
                                <div 
                                    style={{
                                        minHeight: '140px', backgroundColor: 'white', 
                                        padding: '20px', textAlign: 'center',
                                        borderRadius: '10px', marginTop: '5px'
                                    }}
                                >
                                    <h2>{x.Name}</h2>
                                </div>
                        )}
                    </Col>
                    <Col>
                        {/* Price Details */}
                        <h3>{x.Name}</h3>
                        <div style={{ fontSize: '1rem' }}>
                            {x.Description}
                        </div>
                        <Row className="text-danger" style={{fontSize: '1.3rem', marginTop: '5px'}}>
                            <Col>
                                {x.SizeDetails}
                            </Col>
                            <Col>
                                {`$${x.Total.toFixed(2)}`}
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={6}>
                        {i === 0 && <CartDetail storeId={storeId} setSelectedPage={setSelectedPage} notify={notify} />}
                    </Col>
                </Row>
            })}
            
            {relatedProducts &&
                <Fragment>
                    <h3 style={{ fontStyle: 'italic', paddingBottom: '1.5rem' }}>Try these great products for you!</h3>
                    <Row style={{ paddingBottom: '1.75rem' }}>
                        {relatedProducts.map((x,i) => {
                            return (
                                <Col lg={3} md={6} sm={6} xs={6} key={i}>
                                    <RelatedProduct props={{
                                        product: x,
                                        socket: socket,
                                        loading: loading,
                                        setLoading: setLoading,
                                        notify
                                    }} />
                                </Col>
                            )
                        })}
                    </Row>
                </Fragment>
            }
            <Row style={{justifyContent: 'center'}}>
                <Button size="lg" variant="success" style={{marginRight: '20px', marginTop: '1rem'}} onClick={checkoutClick}>
                    Checkout
                </Button>
                <Button size="lg" style={{marginTop: '1rem'}} onClick={() => continueShoppingClick()}>
                    Continue Shopping
                </Button>
            </Row>
        </Container>
    )
}